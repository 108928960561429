<script setup lang="ts">
import { computed, ref } from 'vue';
import api from '@/api';
import { ContentsContext, Feature, SearchData } from '@/api/tracking';
import noImage1to1 from '@/assets/noimage-1to1.jpg';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import DocumentSentenceDisplay from '@/components/common/adp-document/document-sentence-display.vue';
import UserDocumentSimilarDocumentCard from '@/components/user-document/user-document-similar-document-card.vue';
import {
  UserDocument,
  UserDocumentEventName,
  UserDocumentViewInfo,
} from '@/types';
import { imageErrorHandler } from '@/utils/errorHandler';
import {
  getDocumentUrl,
  getFileTypeIcon,
  getFolderName,
  getIsPageNumberAvailable,
  getModifiedAt,
} from '@/utils/user-document/common';
import { featureFlags } from '@/featureFlags';

interface Props {
  userDocument: UserDocument;
  badgeNumber?: number;
  rankInWholeFeed?: number;
  feature?: Feature;
  searchData?: SearchData;
  contentsContext: ContentsContext;
}

const SIMILAR_DOCUMENTS_INCREMENT_NUMBER = 10;
const SIMILAR_DOCUMENTS_INITIAL_NUMBER = 3;

const props = defineProps<Props>();
const imageUrl = computed(() => props.userDocument.image_url || noImage1to1);
const isPageNumberAvailable = computed(() =>
  getIsPageNumberAvailable(props.userDocument),
);
const fileTypeIcon = computed(() => getFileTypeIcon(props.userDocument));
const folderName = computed(() => getFolderName(props.userDocument));
const documentUrl = computed(() => getDocumentUrl(props.userDocument));
const modifiedAt = computed(() => getModifiedAt(props.userDocument));

const highlightKeywords = computed(() => {
  const hit_keywords = props.userDocument.hit_keywords ?? [];
  const synonym_keywords = props.userDocument.synonym_keywords ?? [];
  return [...hit_keywords, ...synonym_keywords]
    .filter(keyword => keyword.length > 0)
    .sort(keyword => -keyword.length);
});

const trackingUserDocumentEvent = async (eventName: UserDocumentEventName) =>
  await api.trackingUserDocumentEvent(eventName, {
    page: {
      name: 'user_document_search',
    },
    feature: 'user_document_search',
    rank: props.rankInWholeFeed ?? undefined,
    user_document: {
      id: props.userDocument.id,
    },
    contents_context: props.contentsContext as Extract<
      ContentsContext,
      { event_name: 'user_document_search' }
    >,
  } as UserDocumentViewInfo);

const trackingSimilarUserDocumentsShowMoreEvent = async () =>
  await api.trackingSimilarUserDocumentsShowMoreEvent(
    'similar_user_documents_show_more',
    {
      page: {
        name: 'user_document_search',
      },
      feature: 'user_document_search',
      rank: props.rankInWholeFeed ?? undefined,
      contents_context: props.contentsContext as Extract<
        ContentsContext,
        { event_name: 'user_document_search' }
      >,
    },
  );

const showMoreSimilarDocuments = () => {
  displaySimilarDocumentNumber.value = Math.min(
    props.userDocument.similar_documents?.length ?? 0,
    displaySimilarDocumentNumber.value + SIMILAR_DOCUMENTS_INCREMENT_NUMBER,
  );
  trackingSimilarUserDocumentsShowMoreEvent();
};

const ANDEV_4946_SIMILAR_DOCUMENTS_AGGREGATION =
  featureFlags.ANDEV_4946_SIMILAR_DOCUMENTS_AGGREGATION;

const displaySimilarDocumentNumber = ref(
  Math.min(
    props.userDocument.similar_documents?.length ?? 0,
    SIMILAR_DOCUMENTS_INITIAL_NUMBER,
  ),
);

const displaySimilarDocuments = computed(
  () =>
    props.userDocument.similar_documents?.slice(
      0,
      displaySimilarDocumentNumber.value,
    ) ?? [],
);
</script>

<template>
  <div class="user-document-card">
    <div class="user-document-representative-document">
      <div class="user-document-left">
        <div class="user-document-data-source-info c-text c-text--xs">
          <div class="user-document-page-number">
            <div>
              <DgrIcon :name="fileTypeIcon" />
            </div>
            <div class="spacing-02"></div>
            <template v-if="isPageNumberAvailable">
              <div class="page-number">
                ページ：{{ userDocument.page_number }}
              </div>
            </template>
            <div v-if="badgeNumber" class="number-badge">
              {{ badgeNumber }}
            </div>
          </div>
          <div class="spacing-04"></div>
          <a
            :href="userDocument.folder_url"
            target="_blank"
            rel="noopener noreferrer"
            class="user-document-data-source-name"
            @click="trackingUserDocumentEvent('user_document_folder_view')"
          >
            <DgrIcon :keep-fill="false" name="folder" />
            <div class="spacing-04"></div>
            <div class="user-document-data-source-name-text">
              {{ folderName }}
            </div>
          </a>
        </div>
        <div class="spacing-04"></div>
        <div class="user-document-title c-title c-title--xm">
          <a
            :href="documentUrl"
            target="_blank"
            rel="noopener noreferrer"
            @click="trackingUserDocumentEvent('user_document_view')"
          >
            {{ userDocument.title }}
          </a>
        </div>
        <div class="spacing-04"></div>
        <DocumentSentenceDisplay
          :text="userDocument.chunk_text"
          :highlight-keywords="highlightKeywords"
          :line-clamp="2"
        ></DocumentSentenceDisplay>
        <div class="spacing-04"></div>
        <div class="user-document-bottom">
          <div class="c-text c-text--s">更新日：{{ modifiedAt }}</div>
          <div class="c-text c-text--s">
            更新者：{{ userDocument.last_updated_by }}
          </div>
        </div>
      </div>
      <div>
        <a :href="documentUrl" target="_blank" rel="noopener noreferrer">
          <img
            class="user-document-thumbnail"
            loading="lazy"
            :src="imageUrl"
            :data-src="imageUrl"
            @error="imageErrorHandler($event, '3to1')"
          />
        </a>
      </div>
    </div>
    <div
      class="user-document-similar-documents"
      v-if="
        ANDEV_4946_SIMILAR_DOCUMENTS_AGGREGATION &&
        userDocument.similar_documents &&
        userDocument.similar_documents.length > 0
      "
    >
      <div class="c-title c-title--s">
        同一ページをもつ情報
        <span class="similar-documents-count c-text c-text--s"
          >・{{ userDocument.similar_documents.length }}件</span
        >
      </div>
      <UserDocumentSimilarDocumentCard
        :user-document-similar-document="similarDocument"
        :parent-user-document="userDocument"
        :parent-rank="rankInWholeFeed"
        :rank-in-parent="index + 1"
        :contents-context="props.contentsContext"
        v-for="(similarDocument, index) in displaySimilarDocuments"
        :key="similarDocument.id"
        :class="{
          'bottom-border': index !== userDocument.similar_documents.length - 1,
        }"
      ></UserDocumentSimilarDocumentCard>
      <button
        class="c-btn c-btn--small show-more-button"
        @click="showMoreSimilarDocuments"
        v-if="
          displaySimilarDocumentNumber < userDocument.similar_documents.length
        "
      >
        さらに表示
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.user-document-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px;
  column-gap: 16px;
  box-sizing: border-box;

  .user-document-representative-document {
    display: flex;
    .user-document-left {
      width: 100%;
      .user-document-bottom {
        display: flex;
        color: $color-gray800;
        font-weight: 400;
        column-gap: 8px;
        padding: 4px 0;
        .user-document-page {
          border: 1px solid $color-gray800;
          border-radius: 2px;
          padding: 0 2px;
        }
      }
    }
    .user-document-thumbnail {
      width: 150px;
      height: 150px;
      object-fit: contain;
      font-weight: 400;
    }
    .user-document-data-source-info {
      padding: 4px 0;
      color: $color-gray800;
      font-weight: 500;
      display: flex;
      align-items: center;
      .user-document-page-number {
        display: flex;
        align-items: center;
        border-right: 1px solid $color-gray400;
        padding-right: 10px;
        .page-number {
          white-space: nowrap;
        }
        .number-badge {
          margin-left: 10px;
        }
      }
      .user-document-data-source-name {
        display: flex;
        align-items: center;
        fill: $color-gray600;
        .user-document-data-source-name-text {
          overflow: hidden;
          display: -webkit-box;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          line-clamp: 1;
          -webkit-line-clamp: 1;
        }
        &:hover {
          .user-document-data-source-name-text {
            text-decoration: underline;
          }
        }
      }
    }
    .user-document-title {
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      word-break: break-all;
      font-weight: 500;
      a:hover {
        text-decoration: underline;
      }
    }
  }
  .user-document-similar-documents {
    border-top: 1px solid $color-gray400;
    margin-top: 8px;
    padding-top: 8px;
    .similar-documents-count {
      color: $color-gray800;
    }
    .bottom-border {
      border-bottom: 1px solid $color-gray400;
    }
    .show-more-button {
      margin: 8px auto 0 auto;
      width: auto;
      padding: 0 8px;
      background-color: white;
      border: 1px solid $color-green600;
      color: $color-green600;
    }
  }
}
</style>
