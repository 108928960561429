<script setup lang="ts">
import { computed, nextTick, ref, watch } from 'vue';
import { DgrCheckbox, DgrIcon } from '@stockmarkteam/donguri-ui';
import { useOutsideClick } from '@/utils/composables/useOutsideClick';
import { TARGET_SOURCES } from '@/utils/survey/constants';
import { useTeamInfo } from '@/utils/swr';

/**
 * Props
 */

const props = defineProps<{
  position: 'top' | 'bottom';
}>();

/**
 * Emits
 */

const emit = defineEmits<{
  'apply-filter': [value: string[]];
}>();

/**
 * Local State
 */

// 契約状態
const { data: teamInfo } = useTeamInfo();
const enableTechnicalLiterature = computed(
  () => teamInfo.value?.enable_technical_literature ?? false,
);
const enableUserDocument = computed(
  () => teamInfo.value?.enable_user_document ?? false,
);

// フィルターの開閉状態と操作
const isOpen = ref(false);
const containerRef = ref<Element>();
const popupRef = ref<Element>();
const openFilter = () => {
  isOpen.value = true;
  nextTick(() => {
    adjustPopupPosition();
  });
};
const closeFilter = () => {
  isOpen.value = false;
  restoreFormValue();
};
const toggleFilter = () => {
  isOpen.value ? closeFilter() : openFilter();
};

// フィルターのクリックイベント(フィルターの外側をクリックした場合は閉じる)
const isClickedOutsideFilter = useOutsideClick([containerRef]);
watch(isClickedOutsideFilter, () => {
  if (isClickedOutsideFilter.value) {
    closeFilter();
  }
});

// フォームの値と操作
const originDocTypes = defineModel('docTypes', {
  default: [],
  required: true,
  type: Array<string>,
});
const docTypes = ref<Array<string>>([]);
const isFiltering = computed(
  () => JSON.stringify(originDocTypes.value) !== JSON.stringify([]),
);

watch(originDocTypes, () => {
  docTypes.value = originDocTypes.value;
});

/**
 * Handlers
 */

const toggleCheckbox = (option: string) => {
  docTypes.value = docTypes.value.includes(option)
    ? docTypes.value.filter(v => v !== option)
    : [...docTypes.value, option];
};

const restoreFormValue = () => {
  docTypes.value = originDocTypes.value;
};

const resetFormValue = () => {
  originDocTypes.value = [];
  closeFilter();
};

const applyFilterWithClose = () => {
  originDocTypes.value = docTypes.value;
  emit('apply-filter', docTypes.value);
  closeFilter();
};

/**
 * ポップアップの位置を調整する関数
 * - 基本的にはボタンの下にポップアップを表示するが、画面下部にスペースがない場場合は上部に表示する
 *
 * NOTE: 画面下部のスペースとポップアップの高さで自動的に上か下かを判断するのがベストだが、実装スピードを優先してpropsで決め打ちにしている
 */
const adjustPopupPosition = () => {
  if (!containerRef.value || !popupRef.value) return;
  if (props.position === 'bottom') return;

  const containerTop = containerRef.value.getBoundingClientRect().top;
  (popupRef.value as HTMLDivElement).style.top =
    `${containerTop - popupRef.value.getBoundingClientRect().height}` + 'px';
};
</script>

<template>
  <div ref="containerRef" class="filter-container">
    <button class="filter-toggle-button" @click="toggleFilter">
      <span class="c-text c-text--m">{{
        isFiltering ? '絞り込み適用中' : '検索対象絞り込み'
      }}</span>
      <DgrIcon name="angle-down" class="c-selectBox__arrow" />
    </button>
    <div
      ref="popupRef"
      v-if="isOpen"
      @click.stop="() => undefined"
      class="popup-container"
    >
      <div class="section-container">
        <template
          v-for="source in TARGET_SOURCES(
            enableTechnicalLiterature,
            enableUserDocument,
          )"
          :key="source.section"
        >
          <p class="c-title c-title--m">{{ source.section }}</p>
          <div class="c-text--m option-container">
            <template v-for="option in source.options" :key="option.value">
              <DgrCheckbox
                :model-value="docTypes.includes(option.value)"
                @update:model-value="() => toggleCheckbox(option.value)"
              >
                {{ option.label }}
              </DgrCheckbox>
            </template>
          </div>
        </template>
      </div>
      <div class="button-container">
        <button
          class="dashboard-button--borderless"
          @click="resetFormValue"
          data-testid="reset-button"
        >
          リセット
        </button>
        <div class="right-buttons">
          <button
            class="dashboard-button"
            @click="closeFilter"
            data-testid="cancel-button"
          >
            キャンセル
          </button>
          <div class="spacing-16"></div>
          <button
            class="dashboard-button--primary"
            @click="applyFilterWithClose"
            data-testid="apply-button"
          >
            適用
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.filter-container {
  position: relative;
}

.filter-toggle-button {
  display: flex;
  align-items: center;
  color: $color-gray1000;
  background: #fff;
  height: 32px;
  border: 1px solid $color-border;
  width: 100%;
  justify-content: space-between;
  padding: 4px 8px;
  gap: 4px;
}

.popup-container {
  position: fixed;
  z-index: var(--z-dropdown);
  box-shadow: 0 1px 5px rgba(74, 74, 74, 0.25);
  background: #fff;
  width: 456px;
  border-radius: 4px;
}

.section-container {
  padding: 16px;
  max-height: 320px;
  overflow-y: auto;
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  gap: 16px;
  align-items: center;
}

.option-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 16px;
  border-top: 1px solid $color-gray400;
}

.right-buttons {
  display: flex;
}

.dashboard-button--borderless {
  color: $color-green600;
}
</style>
