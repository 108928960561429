<script setup lang="ts">
import { onMounted, ref } from 'vue';
// import api from '@/api';
import Item from '@/components/menu/common/item.vue';
import SectionWrapper from '@/components/menu/sections/section-wrapper.vue';
import { Survey } from '@/types/survey';

// const PAGE_OFSET = 20;
// const pagingNum = ref(0);
const surveys = ref<Array<Survey>>([]);

onMounted(async () => {
  // TODO: スレッドの履歴を取得して表示する  (ページング処理必須)
  // surveys.value = await api.fetchSurveys(PAGE_OFSET, pagingNum.value);
});
</script>
<template>
  <SectionWrapper>
    <div class="item-list">
      <div class="title-icon c-title c-title--s">
        <Item :id="'surveys'" :name="'トップ'" :route="{ name: 'surveyTop' }" />
        <span class="title"></span>
      </div>
      <div class="items">
        <ul class="list-style--none">
          <li :key="survey.session_id" v-for="survey in surveys">
            <Item
              :id="'surveys'"
              :name="survey.first_question"
              :route="{ name: 'organizationTags' }"
            />
          </li>
        </ul>
      </div>
    </div>
  </SectionWrapper>
</template>
<style scoped lang="scss">
.list-style--none {
  list-style: none;
}
</style>
