import { DocType, Lang, PostSurveyResponse } from '@/types';
import { STREAMING_DATA_TYPES } from '@/utils/survey/constants';
import { userSession } from '@/utils/userSession';

export async function* postSurvey(
  question: string,
  docTypes: Array<DocType | 'user_document'>,
  langs: Array<Omit<Lang, 'zh'>>,
  cachedExpandedQueries: string[],
): AsyncGenerator<PostSurveyResponse> {
  if (globalThis.fetch === undefined) return; // テスト実行でfetchはundefinedなのでreturn
  // NOTE: v1の機能では要約結果をDBに保存しないため、`survey_session_id`には"new"という文字列を固定で送る
  // v2以降の開発が進んだ際には、`survey-top.vue`から`survey-session.vue`に遷移する前にスレッドの枠だけ作成 (`surveys`テーブルにデータ追加)し、その値を`survey_session_id`に渡すようにする
  // これにより、画面上のURLもサーバーサイドも古いフロントエンドリソースの互換性を考慮した上で開発が可能
  const response = await fetch(`api/v1/survey/new`, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'text/event-stream',
    },
    method: 'POST',
    body: JSON.stringify({
      user_id: userSession.getUserId(),
      question,
      doc_types: docTypes,
      langs,
      cached_expanded_queries: cachedExpandedQueries,
      streaming: true,
    }),
  });

  let chunk = '';
  const reader = response.body?.getReader();
  const decoder = new TextDecoder('utf-8');
  if (response.status !== 200 || !reader) {
    throw new Error('Request failed');
  }
  while (true) {
    const { done, value } = await reader.read();

    if (done) break;
    if (!value) continue;

    chunk += decoder.decode(value, { stream: true });
    if (!chunk.includes('\n')) continue; // データの最後に改行がない場合はまだデータが完全ではないので次のデータを待つ

    const lines = chunk.split('\n');
    chunk = lines.pop() ?? ''; // 最後の行がまだ完全でない可能性があるため、それを次のチャンクと結合

    for (let line of lines) {
      if (!line.startsWith('data: ')) continue;
      line = line.slice(6); // `data: `部分を取り除く

      try {
        const json = JSON.parse(line.trim());

        if (json.type === 'survey_done') return;
        if (STREAMING_DATA_TYPES.includes(json.type)) yield json;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('ストリーミングデータの JSONパースに失敗しました', error);
        break;
      }
    }
  }
  reader.releaseLock();
}
