import { TrackingBaseData } from '@/api/tracking';
import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import Qs from 'qs';
import {
  AdpDocument,
  Article,
  Comment,
  ConfirmForgotPasswordResponse,
  DocType,
  FeedType,
  ForgotPasswordResponse,
  Group,
  Lang,
  NotificationItem,
  NotificationType,
  NotificationUnviewedAndUnreadCounts,
  SetNewPasswordResponse,
  SignInResponse,
  Tag,
  ThemeList,
  UserEntity,
  UserInfo,
  UserRole,
  UserSettings,
  UserSettingsEmail,
  UserSettingsNews,
  UserSettingsNotificationRequest,
  UserSettingsProfile,
  WebPushSubscription,
} from '@/types';
import { userSession } from '@/utils/userSession';

export async function fetchUserInfo(userId: number | null): Promise<UserInfo> {
  const { data } = await axios.get<UserInfo>(
    `/users/${userId}?include_action_counts=false`,
  );
  return data;
}

export async function fetchMyInfo(): Promise<UserInfo> {
  const myUserId = userSession.getUserId();
  return fetchUserInfo(myUserId);
}

export async function fetchNotifications(
  limit = 10,
  page = 1,
  order: 'desc' | 'asc' = 'desc',
  notificationTypes?: NotificationType[],
  unreadOnly = false,
  trackingData = {},
): Promise<NotificationItem[]> {
  const myUserId = userSession.getUserId();
  const { data } = await axios.get<{
    notifications: NotificationItem[];
  }>(`/users/${myUserId}/notifications`, {
    params: {
      limit,
      page,
      order,
      notification_types: notificationTypes,
      unread_only: unreadOnly,
      tracking_data: trackingData,
    },
  });
  return data.notifications;
}

export async function fetchThemeList(
  feedType = 'domestic',
): Promise<ThemeList[]> {
  const myUserId = userSession.getUserId();
  const { data } = await axios.get<{ themes: ThemeList[] }>(
    `/users/${myUserId}/themes?feed_type=${feedType}&include_keyword_feed_counts=false`,
  );
  return data.themes;
}

// NOTE: SWRVを使えない箇所で使用想定
export async function fetchWebPushSubscription(
  endpoint: string,
): Promise<WebPushSubscription | undefined> {
  const userId = userSession.getUserId();
  const { data } = await axios.get<{
    web_push_subscriptions: WebPushSubscription[];
  }>(`/users/${userId}/web_push_subscriptions?${Qs.stringify({ endpoint })}`);
  return data.web_push_subscriptions[0];
}

export async function getPersonalNews(
  feedType: FeedType,
  date: Dayjs,
  page = 1,
  limit = 10,
  execution = 'morning',
): Promise<{ articles: Article[]; count: number }> {
  const myUserId = userSession.getUserId();
  const params = {
    feed_type: feedType,
    date: date.format('YYYY-MM-DD'),
    execution: execution,
    page: page,
    limit: limit,
  };
  const { data } = await axios.get<{
    personal_feeds: Article[];
    count: number;
  }>(`/users/${myUserId}/personal_feeds?${Qs.stringify(params)}`);
  return { articles: data.personal_feeds, count: data.count };
}

export async function fetchUserComments(
  userId: number,
  limit = 10,
  page = 1,
): Promise<Comment[]> {
  const params = {
    limit: limit,
    page: page,
    order: 'desc',
    include_technical_literature: true,
  };
  const { data } = await axios.get<{ user_comments: Comment[] }>(
    `/users/${userId}/comments?${Qs.stringify(params)}`,
  );
  return data.user_comments;
}

export async function fetchUserMarkArticles(
  userId: number,
  limit = 10,
  page = 1,
  memoExists = undefined,
  tags = [],
  lang = [],
  to_date: Date | undefined,
  from_date: Date | undefined,
  query: string | undefined,
  doc_types: DocType[] = [],
): Promise<AdpDocument[]> {
  const params = {
    page,
    limit,
    order: 'desc',
    tags: tags.length > 0 ? tags : undefined,
    memo_exists: memoExists,
    include_technical_literature: true,
    lang,
    to_date: to_date ? dayjs(to_date).format('YYYY-MM-DD') : undefined,
    from_date: from_date ? dayjs(from_date).format('YYYY-MM-DD') : undefined,
    query,
    doc_types,
  };

  const { data } = await axios.get<{ marked_articles: Article[] }>(
    `/users/${userId}/marked_articles`,
    {
      params,
    },
  );
  return data.marked_articles;
}

export async function signout(): Promise<void> {
  await axios.post<void>('/users/signout');
}

export async function signin(
  email: string,
  password: string,
): Promise<SignInResponse> {
  const { data } = await axios.post<SignInResponse>('/users/signin', {
    email: email,
    password: password,
  });
  return data;
}

export async function deleteUser(userId: number): Promise<UserEntity> {
  const { data } = await axios.delete<UserEntity>(`/users/${userId}`);
  return data;
}

export async function updateUser(
  userId: number,
  email: string,
  userName: string,
  role: UserRole,
  productContractId: string | null,
  organizationTagIds: number[],
): Promise<UserEntity> {
  const { data } = await axios.put<UserEntity>(`/users/${userId}`, {
    user_name: userName,
    role: role,
    email: email,
    product_contract_id: productContractId,
    organization_tag_ids: organizationTagIds,
  });
  return data;
}

export async function createUser(
  email: string,
  userName: string,
  role: UserRole,
  productContractId: string | null,
  organizationTagIds: number[],
): Promise<UserEntity> {
  const { data } = await axios.post<UserEntity>('/users', {
    email: email,
    user_name: userName,
    role: role,
    product_contract_id: productContractId,
    organization_tag_ids: organizationTagIds,
  });
  return data;
}

export async function set_new_password(
  email: string,
  password: string,
  session: string,
): Promise<SetNewPasswordResponse> {
  const { data } = await axios.post<SetNewPasswordResponse>(
    '/users/set_new_password',
    {
      email: email,
      password: password,
      session: session,
    },
  );
  return data;
}

export async function changePassword(
  oldPassword: string,
  newPassword: string,
  trackingBaseData: TrackingBaseData,
): Promise<void> {
  const userId = userSession.getUserId();
  await axios.post<void>(`/users/${userId}/change_password`, {
    old_password: oldPassword,
    new_password: newPassword,
    tracking_data: {
      page: {
        name: trackingBaseData.pageName,
        url: trackingBaseData.pageUrl,
      },
    },
  });
}

export async function forgotPassword(
  email: string,
): Promise<ForgotPasswordResponse> {
  const { data } = await axios.post<ForgotPasswordResponse>(
    '/users/forgot_password',
    {
      email: email,
    },
  );
  return data;
}

export async function confirmForgotPassword(
  email: string,
  password: string,
  confirmation_code: string,
): Promise<ConfirmForgotPasswordResponse> {
  const { data } = await axios.post<ConfirmForgotPasswordResponse>(
    '/users/confirm_forgot_password',
    {
      email: email,
      password: password,
      confirmation_code: confirmation_code,
    },
  );
  return data;
}

export async function postFavoriteTheme(
  themeId: number,
): Promise<{ order: number }> {
  const myUserId = userSession.getUserId();
  const { data } = await axios.post<{ order: number }>(
    `/users/${myUserId}/themes/${themeId}/favorites`,
  );
  return data;
}

export async function updateFavoriteTheme(
  themeId: number,
  order: number,
): Promise<void> {
  const myUserId = userSession.getUserId();
  await axios.put<void>(`/users/${myUserId}/themes/${themeId}/favorites`, {
    order: order,
  });
}

export async function deleteFavoriteTheme(themeId: number): Promise<void> {
  const myUserId = userSession.getUserId();
  await axios.delete<void>(`/users/${myUserId}/themes/${themeId}/favorites`);
}

export async function getUserSettings(
  userId: number | null = null,
): Promise<UserSettings> {
  if (userId === null) {
    userId = userSession.getUserId();
  }
  const { data } = await axios.get<UserSettings>(`/users/${userId}/settings`);
  return data;
}

// FIXME: users setting getの処理がfatすぎるので取得するpropertyを絞る
export async function updateUserSettings(
  settings: UserSettings,
  trackingBaseData: TrackingBaseData,
): Promise<void> {
  const myUserId = userSession.getUserId();
  await axios.put<void>(`/users/${myUserId}/settings`, {
    ...settings,
    tracking_data: {
      page: {
        name: trackingBaseData.pageName,
        url: trackingBaseData.pageUrl,
      },
    },
  });
}

// NOTE: useUserSettingsをdeleteするまでの対応
export async function updateUserSettingsEmail(
  settings: UserSettingsEmail,
  trackingBaseData: TrackingBaseData,
): Promise<void> {
  const myUserId = userSession.getUserId();
  await axios.put<void>(`/users/${myUserId}/settings`, {
    ...settings,
    tracking_data: {
      page: {
        name: trackingBaseData.pageName,
        url: trackingBaseData.pageUrl,
      },
    },
  });
}

export async function updateUserSettingsNotification(
  notificationSettings: UserSettingsNotificationRequest,
  trackingBaseData: TrackingBaseData,
): Promise<void> {
  const myUserId = userSession.getUserId();
  await axios.put<void>(`/users/${myUserId}/settings/notification`, {
    ...notificationSettings,
    tracking_data: {
      page: {
        name: trackingBaseData.pageName,
        url: trackingBaseData.pageUrl,
      },
    },
  });
}

export async function updateUserSettingsNews(
  settings: UserSettingsNews,
  trackingBaseData: TrackingBaseData,
): Promise<void> {
  const myUserId = userSession.getUserId();
  await axios.put<void>(`/users/${myUserId}/settings`, {
    ...settings,
    tracking_data: {
      page: {
        name: trackingBaseData.pageName,
        url: trackingBaseData.pageUrl,
      },
    },
  });
}

export async function updateUserSettingsProfile(
  settings: UserSettingsProfile,
  trackingBaseData: TrackingBaseData,
): Promise<void> {
  const myUserId = userSession.getUserId();
  await axios.put<void>(`/users/${myUserId}/settings`, {
    ...settings,
    tracking_data: {
      page: {
        name: trackingBaseData.pageName,
        url: trackingBaseData.pageUrl,
      },
    },
  });
}

export async function resendPasswordEmail(userId: number): Promise<void> {
  await axios.get<void>(`/users/${userId}/admin_reset_password`);
}

export async function fetchGroups(userId: number): Promise<Group[]> {
  const { data } = await axios.get<{ groups: Group[] }>(
    `/users/${userId}/groups?include_unread_comment_count=false`,
  );
  return data.groups;
}

export async function sendGroupView(groupId: number): Promise<void> {
  const userId = userSession.getUserId();
  await axios.put<void>(`/users/${userId}/groups/${groupId}/view`);
}

export async function updateUserArticleMemo(
  articleId: number,
  docType: DocType,
  memo: string,
  articleLang: Lang,
): Promise<void> {
  const userId = userSession.getUserId();
  await axios.put<void>(`/users/${userId}/articles/${articleId}/memos`, {
    content: memo,
    doc_type: docType ?? 'article',
    article_lang: articleLang,
  });
}

export async function deleteUserArticleMemo(
  articleId: number,
  docType: DocType,
  articleLang: Lang,
): Promise<void> {
  const userId = userSession.getUserId();
  const params = new URLSearchParams({
    doc_type: docType,
    article_lang: articleLang,
  }).toString();
  await axios.delete<void>(
    `/users/${userId}/articles/${articleId}/memos?${params}`,
  );
}

export async function updateUserArticleTags(
  articleId: number,
  docType: DocType,
  tags: string[],
  articleLang: Lang,
): Promise<void> {
  const userId = userSession.getUserId();
  await axios.put<void>(`/users/${userId}/articles/${articleId}/tags`, {
    tags: tags,
    doc_type: docType ?? 'article',
    article_lang: articleLang,
  });
}

export async function fetchUserArticleTags(userId: number): Promise<Tag[]> {
  const { data } = await axios.get<{ user_tags: Tag[] }>(
    `/users/${userId}/tags`,
  );
  return data.user_tags;
}

export async function fetchNotificationsUnreadCounts<
  T extends Record<string, readonly NotificationType[]>,
>(notificationGrouping: T): Promise<NotificationUnviewedAndUnreadCounts<T>> {
  const userId = userSession.getUserId();
  const response = await axios.get<{
    counts: NotificationUnviewedAndUnreadCounts<T>;
  }>(`/users/${userId}/notifications/unviewed_unread_counts`, {
    params: {
      notification_grouping: notificationGrouping,
    },
  });
  return response.data.counts;
}

export async function viewNotifications(
  notificationTypes?: readonly NotificationType[] | undefined,
): Promise<void> {
  const userId = userSession.getUserId();
  await axios.put<void>(`/users/${userId}/notifications/view`, {
    notification_types: notificationTypes,
  });
}

export async function readNotifications(
  notificationTypes?: readonly NotificationType[],
): Promise<void> {
  const userId = userSession.getUserId();
  await axios.put<void>(`/users/${userId}/notifications/read`, {
    notification_types: notificationTypes,
  });
}

export async function readNotification(notificationId: number): Promise<void> {
  const userId = userSession.getUserId();
  await axios.put<void>(`/users/${userId}/notification/${notificationId}/read`);
}

export async function addUserTheme(themeId: number): Promise<void> {
  const userId = userSession.getUserId();
  await axios.post<void>(`/users/${userId}/themes/${themeId}`);
}

export async function deleteUserTheme(themeId: number): Promise<void> {
  const userId = userSession.getUserId();
  await axios.delete<void>(`/users/${userId}/themes/${themeId}`);
}

export async function updateUserJobType(jobtTypeId: number): Promise<void> {
  const userId = userSession.getUserId();
  await axios.put<void>(`/users/${userId}/job_types`, {
    id: jobtTypeId,
  });
}

export async function updateUserNeedOnboarding(value: boolean): Promise<void> {
  const userId = userSession.getUserId();
  await axios.put<void>(`/users/${userId}/need_onboarding`, {
    value: value,
  });
}
