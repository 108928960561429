import { DocType } from '@/types';

// 要約v1で使用する、検索対象の絞り込み項目
export const TARGET_SOURCES = (
  enableTechnicalLiterature: boolean,
  enableUserDocument: boolean,
) => [
  {
    section: '公開情報',
    options: [
      {
        label: 'ニュース',
        value: 'article',
      },
      ...(enableTechnicalLiterature
        ? [
            {
              label: '論文',
              value: 'research_paper',
            },
            {
              label: '特許',
              value: 'patent',
            },
          ]
        : []),
      {
        label: 'レポート',
        value: 'report',
      },
    ],
  },
  ...(enableUserDocument
    ? [
        {
          section: '社内情報',
          options: [
            {
              label: 'すべて',
              value: 'user_document',
            },
          ],
        },
      ]
    : []),
];

// TARGET_SOURCESのoptionsの全てのvalue
export const ALL_DOC_TYPES = (
  enableTechnicalLiterature: boolean,
  enableUserDocument: boolean,
) =>
  TARGET_SOURCES(enableTechnicalLiterature, enableUserDocument).flatMap(
    source => source.options.map(option => option.value),
  ) as Array<DocType | 'user_document'>;

// surveyのstreamingデータのtype
export const STREAMING_DATA_TYPES = [
  'token' /* 要約本文 */,
  'source' /* 要約に使用したソース (検索にヒットしたドキュメント) */,
  'no_hit_sources' /* 検索で1件もドキュメントが引っ掛からなかった時に返ってくる */,
  'sources_done' /* 要約に使用したソースが全て返却し終わったら返ってくる */,
  'error' /* モデルが要約を生成する過程のどこかでエラーが発生した時に返ってくる */,
] as const;

export const SURVEY_DONE =
  'survey_done' as const; /* surveyのAPIが完了した時に返ってくる */
