<script setup lang="ts">
import { onMounted, ref } from 'vue';
import api from '@/api';
import { SHAREPOINT_ERROR_MESSAGES } from '@/constants';
import { isAxiosError } from 'axios';
import LoadableButton from '@/components/common/atoms/loadable-button.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import { SharepointSite } from '@/types';

interface Props {
  isOpen: boolean;
  isDisabled: boolean;
}
defineProps<Props>();

const { createSnackbar } = useSnackbar();

const site = ref<SharepointSite>({
  id: null,
  site_url: '',
});
const isSubmitting = ref(false);

const emit = defineEmits<{
  onClose: [void];
}>();
const hideModal = () => {
  emit('onClose');
};

const getSharepointSites = async () => {
  const response = await api.getSharepointSites();
  return response;
};

const submit = async () => {
  isSubmitting.value = true;
  try {
    await api.postOneSharepointSite(site.value.site_url);
    getSharepointSite();
    createSnackbar({
      message: 'サイトを更新しました',
      type: 'success',
    });
    hideModal();
  } catch (error) {
    let errorMessage = 'サイトの更新に失敗しました';
    if (isAxiosError(error)) {
      const serverError: string = error.response?.data.error;
      if (serverError in SHAREPOINT_ERROR_MESSAGES) {
        errorMessage = SHAREPOINT_ERROR_MESSAGES[serverError];
      }
    }
    createSnackbar({
      message: errorMessage,
      type: 'error',
    });

    // eslint-disable-next-line no-console
    console.error(error);
  }
  isSubmitting.value = false;
};

const getSharepointSite = async () => {
  const response = await getSharepointSites();
  site.value = {
    id: null,
    site_url: '',
  };
  if (response.length > 0) {
    site.value = response[0];
  }
};

onMounted(async () => {
  getSharepointSite();
});
</script>

<template>
  <Teleport to="body">
    <sm-dialog
      v-if="isOpen"
      @close="hideModal"
      class="screen-center no-padding auto-width"
    >
      <template #header>
        <div class="c-dialog__title modal-header">サイトを編集</div>
      </template>
      <template #body>
        <div class="modal-body">
          <div class="modal-form">
            <label class="c-title c-title--m" for="site-url-form"
              >サイトURL</label
            >
            <div class="spacing-08"></div>
            <input type="text" v-model="site.site_url" id="site-url-form" />
            <div class="text-warning c-text c-text--s" v-if="isDisabled">
              現在はサイトURLを変更できません
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="modal-cancel-button c-btn c-btn--auto c-btnOutline"
              @click="hideModal"
            >
              キャンセル
            </button>
            <div class="spacing-16"></div>
            <LoadableButton
              class="modal-create-button c-btn c-btn--auto c-btn--AnewsPrimary"
              :class="{ disabled: isDisabled }"
              :disabled="isDisabled"
              @click="submit"
              :is-loading="isSubmitting"
            >
              保存
            </LoadableButton>
          </div>
        </div>
      </template>
    </sm-dialog>
  </Teleport>
</template>

<style lang="scss" scoped>
.modal-header {
  padding: 5px 0px;
}
.modal-body {
  width: 600px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .modal-form {
    padding: 25px 24px 0px 24px;
    .current-client-secret {
      color: $color-gray800;
    }
    input {
      width: 500px;
    }
    .text-warning {
      margin-top: 25px;
      color: $color-orange1000;
    }
  }
}
.modal-footer {
  padding: 9px 16px 16px 16px;
  display: flex;
  justify-content: flex-end;
}
</style>
