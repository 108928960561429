import { VueGtag } from 'vue-gtag';
import { NetworkGraphEdges, NetworkGraphNodes } from '@/types/lab';
import { userSession } from '@/utils/userSession';

export const gaTracker = {
  getTrackingId: function () {
    const value = import.meta.env.VITE_GA_TRACKING_ID;
    return value;
  },
  config: function (gtag: VueGtag) {
    const userId = userSession.getUserId();
    const teamId = userSession.getTeamId();

    if (userId === null || teamId === null) return;

    gtag.config({
      custom_map: { dimension1: 'team_id' },
      team_id: teamId.toString(),
    });
    gtag.set({
      user_id: userId,
    });
  },
  clearConfig: function (gtag: VueGtag) {
    gtag.config({
      custom_map: { dimension1: 'team_id' },
      team_id: undefined,
    });
    gtag.set({
      user_id: undefined,
    });
  },
  trackArticleView: function (gtag: VueGtag) {
    const teamId = userSession.getTeamIdAsString();
    gtag.event('click', {
      event_category: 'link',
      event_label: '外部URL',
      team_id: teamId,
    });
  },
  trackCreateComment: function (gtag: VueGtag) {
    const teamId = userSession.getTeamIdAsString();
    gtag.event('create', {
      event_category: 'comment',
      event_label: 'コメント投稿',
      team_id: teamId,
    });
  },
  trackEditComment: function (gtag: VueGtag) {
    const teamId = userSession.getTeamIdAsString();
    gtag.event('update', {
      event_category: 'comment',
      event_label: 'コメント編集',
      team_id: teamId,
    });
  },
  trackDeleteComment: function (gtag: VueGtag) {
    const teamId = userSession.getTeamIdAsString();
    gtag.event('delete', {
      event_category: 'comment',
      event_label: 'コメント削除',
      team_id: teamId,
    });
  },
  trackCreateTheme: function (gtag: VueGtag, _themeName: string) {
    const teamId = userSession.getTeamIdAsString();
    gtag.event('create', {
      event_category: 'theme',
      event_label: 'テーマ作成',
      team_id: teamId,
    });
  },
  trackUpdateTheme: function (gtag: VueGtag, _themeName: string) {
    const teamId = userSession.getTeamIdAsString();
    gtag.event('update', {
      event_category: 'theme',
      event_label: 'テーマ編集',
      team_id: teamId,
    });
  },
  trackDeleteTheme: function (gtag: VueGtag, _themeName: string) {
    const teamId = userSession.getTeamId();
    gtag.event('delete', {
      event_category: 'theme',
      event_label: 'テーマ削除',
      team_id: teamId,
    });
  },
  trackShowMoreFeed: function (gtag: VueGtag) {
    const teamId = userSession.getTeamId();
    gtag.event('click', {
      event_category: 'show more',
      event_label: 'もっと記事を表示',
      team_id: teamId,
    });
  },
  trackToggleFavorite: function (gtag: VueGtag) {
    const teamId = userSession.getTeamId();
    gtag.event('toggle', {
      event_category: 'favorite',
      event_label: 'お気に入り切り替え',
      team_id: teamId,
    });
  },

  // Anews Labs テンプレートの作成
  trackCreateTemplate: function (gtag: VueGtag, templateName: string) {
    const teamId = userSession.getTeamId();
    gtag.event('create', {
      event_category: 'labs.aiagent.template',
      event_label: templateName,
      team_id: teamId,
    });
  },
  // Anews Labs テンプレート作成時のキーワード
  trackCreateTemplateKeyword: function (
    gtag: VueGtag,
    templateName: string,
    keyword: string,
  ) {
    const teamId = userSession.getTeamId();
    gtag.event('create', {
      event_category: `labs.aiagent.template_${templateName}`,
      event_label: keyword,
      team_id: teamId,
    });
  },
  // Anews Labs SFUC 検索
  trackSearchSFUC: function (gtag: VueGtag, label: string) {
    const teamId = userSession.getTeamId();
    gtag.event('search', {
      event_category: 'labs.sfuc',
      event_label: label,
      team_id: teamId,
    });
  },
  // Anews Labs SFUC リンククリック
  trackClickSFUCLink: function (gtag: VueGtag, label: string) {
    const teamId = userSession.getTeamId();
    gtag.event('click_description', {
      event_category: 'labs.sfuc',
      event_label: label,
      team_id: teamId,
    });
  },
  // Anews Labs SFUC Good Reaction
  trackReactionGoodSFUC: function (gtag: VueGtag, label: string) {
    const teamId = userSession.getTeamId();
    gtag.event('reaction_good', {
      event_category: 'labs.sfuc',
      event_label: label,
      team_id: teamId,
    });
  },
  // Anews Labs SFUC Bad Reaction
  trackReactionBadSFUC: function (gtag: VueGtag, label: string) {
    const teamId = userSession.getTeamId();
    gtag.event('reaction_bad', {
      event_category: 'labs.sfuc',
      event_label: label,
      team_id: teamId,
    });
  },
  // Anews Labs SFUC Bad Reaction Comment
  trackReactionBadCommentSFUC: function (gtag: VueGtag, label: string) {
    const teamId = userSession.getTeamId();
    gtag.event('reaction_bad_comment', {
      event_category: 'labs.sfuc',
      event_label: label,
      team_id: teamId,
    });
  },
  // Anews Labs SFUC 公開情報で調べる
  trackClickSFUCOpenAsAnews: function (gtag: VueGtag, label: string) {
    const teamId = userSession.getTeamId();
    gtag.event('open_as_anews', {
      event_category: 'labs.sfuc',
      event_label: label,
      team_id: teamId,
    });
  },
  // Anews Labs SFUC 社内情報で調べる
  trackClickSFUCOpenAsAnewsUserDocumentSearch: function (
    gtag: VueGtag,
    label: string,
  ) {
    const teamId = userSession.getTeamId();
    gtag.event('open_as_anews_user_document_search', {
      event_category: 'labs.sfuc',
      event_label: label,
      team_id: teamId,
    });
  },
  // Anews Labs SFUC 市場構造を調べる
  trackClickSFUCOpenAsAstrategyRelationNet: function (
    gtag: VueGtag,
    label: string,
  ) {
    const teamId = userSession.getTeamId();
    gtag.event('open_as_astrategy_relation_net', {
      event_category: 'labs.sfuc',
      event_label: label,
      team_id: teamId,
    });
  },
  // Anews Labs SFUC 事例を見る
  trackClickSFUCOpenAsAstrategyCaseExample: function (
    gtag: VueGtag,
    label: string,
  ) {
    const teamId = userSession.getTeamId();
    gtag.event('open_as_astrategy_case_examples', {
      event_category: 'labs.sfuc',
      event_label: label,
      team_id: teamId,
    });
  },
  // Anews Labs AIに聞く
  trackAskAi: function (gtag: VueGtag, question: string) {
    const teamId = userSession.getTeamId();
    gtag.event('askai', {
      event_category: 'labs.aiagent.editoroption',
      event_label: question.slice(0, 100),
      team_id: teamId,
    });
  },
  // Anews Labs AIと探す
  trackSearchWithAi: function (gtag: VueGtag, question: string) {
    const teamId = userSession.getTeamId();
    gtag.event('search', {
      event_category: 'labs.aiagent.editoroption',
      event_label: question.slice(0, 100),
      team_id: teamId,
    });
  },
  // Anews Labs 要約する
  trackSummarize: function (gtag: VueGtag, text: string) {
    const teamId = userSession.getTeamId();
    gtag.event('summarize', {
      event_category: 'labs.aiagent.editoroption',
      event_label: text.slice(0, 100),
      team_id: teamId,
    });
  },
  // Labs API 実行
  trackRequestDemoAPI: function (gtag: VueGtag, apiName: string) {
    const teamId = userSession.getTeamId();
    gtag.event('requestapi', {
      event_category: 'labs.apidemo',
      event_label: apiName,
      team_id: teamId,
    });
  },

  /**
   * Labs ネットワークグラフ 画面を表示したか
   * @param gtag
   * @param graph_type グラフ種別 'organization_tag','member'
   */
  trackViewNetworkgraph: function (gtag: VueGtag, graph_type: string) {
    const trackingData: {
      graph_type: string;
    } = {
      graph_type,
    };

    gtag.event('view_networkgraph', trackingData);
  },
  /**
   * Labs ネットワークグラフ グラフ描画
   * @param gtag
   * @param organization_tag_ids 組織タグID
   * @param contractId 契約ID : メンバーの場合のみ
   */
  trackDrawNetworkgraphForMember: function (
    gtag: VueGtag,
    organization_tag_ids: number[],
    contract_id: string[],
  ) {
    const trackingData: {
      graph_type: string;
      organization_tag_ids: number[];
      contract_id: string[];
    } = {
      graph_type: 'member',
      organization_tag_ids,
      contract_id,
    };

    gtag.event('draw_networkgraph', trackingData);
  },
  /**
   * Labs ネットワークグラフ グラフ描画
   * @param gtag
   * @param organization_tag_names 組織タグ
   * @param from_date 検索期間from
   * @param to_date 検索期間to
   */
  trackDrawNetworkgraphForOrganizationTag: function (
    gtag: VueGtag,
    organization_tag_names: string[],
    from_date: string,
    to_date: string,
  ) {
    const trackingData: {
      graph_type: string;
      organization_tag_names: string[];
      from_date: string;
      to_date: string;
    } = {
      graph_type: 'organization_tag',
      organization_tag_names,
      from_date,
      to_date,
    };

    gtag.event('draw_networkgraph', trackingData);
  },
  /**
   * Labs ネットワークグラフ グラフノードのクリック
   * @param gtag
   * @param node_type ノードの種別
   * @param node_label ノードのラベル
   * @param user_id ユーザID
   */
  trackClickNetworkgraphNodeForMember: function (
    gtag: VueGtag,
    node_type: string,
    node_label: string,
    user_id?: number,
  ) {
    const trackingData: {
      graph_type: string;
      node_label: string;
      node_type: string;
      user_id?: number;
    } = {
      graph_type: 'member',
      node_label,
      node_type,
    };
    if (user_id) {
      trackingData.user_id = user_id;
    }
    gtag.event('click_networkgraph_node', trackingData);
  },
  /**
   * Labs ネットワークグラフ グラフノードのクリック
   * @param gtag
   * @param node_type ノードの種別
   * @param node_label ノードのラベル
   */
  trackClickNetworkgraphNodeForOrganizationTag: function (
    gtag: VueGtag,
    node_type: string,
    node_label: string,
  ) {
    const trackingData: {
      graph_type: string;
      node_label: string;
      node_type: string;
    } = {
      graph_type: 'organization_tag',
      node_label,
      node_type,
    };
    gtag.event('click_networkgraph_node', trackingData);
  },
  /**
   * Labs ネットワークグラフ グラフエッジのクリック
   * @param gtag
   * @param graph_type グラフ種別 'organization_tag','member'
   * @param node1 接続ノード1
   * @param node2 接続ノード2
   * @param relation_count ノード間のRelation数
   */
  trackClickNetworkgraphEdge: function (
    gtag: VueGtag,
    graph_type: string,
    node1: NetworkGraphNodes,
    node2: NetworkGraphNodes,
    relation_count: number,
  ) {
    const trackingData: {
      graph_type: string;
      node1_label: string;
      node2_label?: string;
      relation_count?: string;
    } = {
      graph_type,
      node1_label: node1.label,
      node2_label: node2.label,
      relation_count: relation_count.toString(),
    };

    gtag.event('click_networkgraph_edge', trackingData);
  },
  /**
   * Labs ネットワークグラフ 右側インフォメーション欄でのユーザ情報参照
   * @param gtag
   * @param graph_type グラフ種別 'organization_tag','member'
   * @param source ソース情報
   * @param user_name ユーザ名
   * @param user_id ユーザID
   */
  trackClickNetworkInfoUser: function (
    gtag: VueGtag,
    graph_type: string,
    source: NetworkGraphNodes | NetworkGraphEdges | null,
    user_name: string,
    user_id: number,
  ) {
    const trackingData: {
      graph_type: string;
      source?: string;
      user_name: string;
      user_id: string;
    } = {
      graph_type,
      user_name,
      user_id: user_id.toString(),
    };

    // sourceがノードの場合
    if (source === null) {
      trackingData['source'] = 'null';
    }
    // sourceがノードの場合
    else if ('type' in source) {
      trackingData['source'] = `node: ${source.label} ${source.type} `;
    } else {
      trackingData['source'] = 'edge';
    }

    gtag.event('click_networkgraph_info_user', trackingData);
  },
  /**
   * Labs ネットワークグラフ 右側インフォメーション欄での記事情報参照
   * @param gtag
   * @param graph_type グラフ種別 'organization_tag','member'
   * @param source ソース情報
   * @param article_id 記事ID
   */
  trackClickNetworkInfoArticle: function (
    gtag: VueGtag,
    graph_type: string,
    source: NetworkGraphNodes | NetworkGraphEdges | null,
    article_id: number,
  ) {
    const trackingData: {
      graph_type: string;
      article_id: string;
      source?: string;
    } = {
      graph_type,
      article_id: article_id.toString(),
    };
    // sourceがノードの場合
    if (source === null) {
      trackingData['source'] = 'null';
    }
    // sourceがノードの場合
    else if ('type' in source) {
      trackingData['source'] = `node: ${source.label} ${source.type} `;
    } else {
      trackingData['source'] = 'edge';
    }

    gtag.event('click_networkgraph_info_article', trackingData);
  },
  trackClickNetworkInfoSearch: function (
    gtag: VueGtag,
    graph_type: string,
    source: NetworkGraphNodes | NetworkGraphEdges | null,
  ) {
    const trackingData: {
      graph_type: string;
      source?: string;
    } = {
      graph_type,
    };

    if (source === null) {
      trackingData['source'] = 'null';
    }
    // sourceがノードの場合
    else if ('type' in source) {
      trackingData['source'] = `node: ${source.label} ${source.type} `;
    } else {
      trackingData['source'] = 'edge';
    }

    gtag.event('click_networkgraph_info_search', trackingData);
  },
};
