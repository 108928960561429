<script setup lang="ts">
/**
 * スレッドの内の1つの塊（クエリ, ソース, 回答の1セット）を表すコンポーネント
 */
import { computed, inject, Ref, ref, watch } from 'vue';
import { DOC_TYPE_LABELS } from '@/constants';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import SkeletonCard from '@/components/survey/document/skeleton-card.vue';
import SurveyAdpDocumentCard from '@/components/survey/document/survey-adp-document-card.vue';
import SurveyReportCard from '@/components/survey/document/survey-adp-report-card.vue';
import SurveyUserDocumentCard from '@/components/survey/document/survey-user-document-card.vue';
import SurveyContentRenderer from '@/components/survey/generate-content/survey-content-renderer.vue';
import { Part } from '@/types';
import { AdpDocument, isUserDocument, UserDocument } from '@/types';
import { closeAllDocumentsKey } from '@/utils/injectionKeys';
import SkeletonSentence from './document/skeleton-sentence.vue';

interface Props {
  question: string;
  sources: Array<AdpDocument | UserDocument>;
  summary: Array<Part>;
  isNoHitSources: boolean; // questionで検索した結果が0件の場合
  isError: boolean; // 何かしらエラーが発生した場合 (modelが要約生成中に通信エラーなど)
}

const props = defineProps<Props>();

const emit = defineEmits<{
  'update-document': [{ sourceIndex: number; document: AdpDocument }];
}>();

// ドキュメントを表示するかどうかのフラグ (最初からドキュメントすべてを展開すると最大20件と多くスクロールが面倒なため、最初は1.5件ほどしか見せない)
const isAllDocumentsVisible = ref(false);
const closeAllDocuments = inject(closeAllDocumentsKey) as Ref<boolean>;
watch(closeAllDocuments, newValue => {
  if (newValue) isAllDocumentsVisible.value = false;
});

const displayedDocuments = computed(() => {
  if (!props.sources) return [];
  if (props.sources.length < 2) return props.sources;

  return isAllDocumentsVisible.value
    ? props.sources
    : props.sources.slice(0, 2);
});

const showAllDocuments = () => {
  isAllDocumentsVisible.value = true;
};

// 検索に引っかかったドキュメントのみで表示するメッセージを生成する
const documentTypes = computed<Array<keyof typeof DOC_TYPE_LABELS>>(() => {
  const types = new Set<keyof typeof DOC_TYPE_LABELS>();
  if (props.sources) {
    props.sources.forEach(source => {
      types.add(source.doc_type);
    });
  }
  return Array.from(types);
});

const documentTypesMessage = computed(() => {
  const types = documentTypes.value.map(type => DOC_TYPE_LABELS[type] || type);
  const totalCount = props.sources.length;
  return `${totalCount}件の${types.join('、')}を検索しました`;
});
</script>

<template>
  <template v-if="props.isError">
    <p class="error-message">
      申し訳ありませんが、現在こちらの回答を生成することができません。後ほどお試しください。
    </p>
  </template>
  <template v-else>
    <div class="survey-history-container">
      <h2 class="c-title c-title--xl">{{ question }}</h2>
      <div class="spacing-16"></div>
      <!-- 検索結果が0ヒット -->
      <template v-if="props.isNoHitSources">
        <div class="generate-contents">
          <div class="generate-contents__header">
            <DgrIcon
              name="sparkles-fill"
              :keep-fill="false"
              class="generate-contents__ai-spark-icon"
            />
            <h3 class="c-title c-title--l">要約</h3>
          </div>
        </div>
        <div class="spacing-08"></div>
        <p>
          {{ props.question }}
          に関するソースを見つけられなかったため要約を生成できませんでした。
        </p>
        <p>
          質問をより明確にするか、要約に使用する情報源を変更することで要約を作成できる可能性があります。
        </p>
      </template>
      <template v-else>
        <!-- ソース (検索結果) -->
        <div v-if="displayedDocuments.length > 0">
          <div class="searching-word c-title c-title--m">
            <DgrIcon name="search" :keep-fill="false" />
            <p>{{ documentTypesMessage }}</p>
          </div>
          <div class="spacing-08"></div>
          <div
            v-for="(document, index) in displayedDocuments"
            :key="document.id"
            :class="{
              'half-transparent last-card':
                !isAllDocumentsVisible &&
                displayedDocuments.length >= 2 &&
                index === displayedDocuments.length - 1,
            }"
          >
            <SurveyUserDocumentCard
              v-if="isUserDocument(document)"
              :user-document="document"
              :badge-number="index + 1"
            />
            <SurveyReportCard
              v-else-if="document.doc_type === 'report'"
              page-name="survey"
              :adp-report="document"
              :rank-in-whole-feed="index + 1"
              :badge-number="index + 1"
            />
            <SurveyAdpDocumentCard
              v-else
              :adp-document="document"
              :badge-number="index + 1"
              :user-enable-translate-title="true"
              :is-narrow="true"
              @update-document="
                document =>
                  emit('update-document', { sourceIndex: index, document })
              "
            />
            <div class="spacing-08"></div>
          </div>
        </div>
        <!-- 記事カード ローディング -->
        <div
          class="adp-document-card-skelton-wrapper"
          v-if="displayedDocuments.length === 0"
        >
          <div class="searching-word c-title c-title--m">
            <DgrIcon name="search" :keep-fill="false" />
            検索しています
          </div>
          <div class="spacing-08"></div>
          <template :key="index" v-for="index in 2">
            <SkeletonCard :number-of-lines="3"></SkeletonCard>
            <div class="spacing-08"></div>
          </template>
        </div>
        <button
          v-if="displayedDocuments.length >= 2 && !isAllDocumentsVisible"
          @click="showAllDocuments"
          class="show-all-button"
        >
          すべて表示
        </button>
        <div class="spacing-16"></div>
        <div class="generate-contents">
          <div class="generate-contents__header">
            <DgrIcon
              name="sparkles-fill"
              :keep-fill="false"
              class="generate-contents__ai-spark-icon"
            />
            <h3 class="c-title c-title--l">要約</h3>
          </div>
        </div>
        <div class="spacing-08"></div>
        <SurveyContentRenderer
          v-if="props.summary.length > 0"
          :content-parts="props.summary"
          :enable-animation="true"
        />
        <!-- 要約 ローディング -->
        <SkeletonSentence :number-of-lines="5" v-else />

        <!-- TODO: 生成した要約に対するフィードバックを出来るようにする -->
        <!-- <div class="summary-feedback">
          <Feedback :request-id="'dummy'" />
        </div> -->
      </template>
    </div>
  </template>
</template>

<style lang="scss" scoped>
.survey-history-container {
  border-bottom: 1px solid $color-gray400;
  margin: 8px 0 16px;
  padding-bottom: 16px;
}

h2 {
  padding: 8px 0;
}

.error-message {
  margin: 16px 0;
  padding-bottom: 16px;
  border-bottom: 1px solid $color-gray400;
}

.searching-word {
  display: flex;
  gap: 8px;
  padding: 6px;
}

/* ----- "すべて表示"で展開するまでは上限数のカードのみを表示 (最後のカードの下半分は半透明にする) ----- */
.last-card {
  height: 50% !important;
  overflow: hidden;
  mask-image: linear-gradient(to bottom, black 10%, transparent 90%);
  -webkit-mask-image: linear-gradient(to bottom, black 10%, transparent 90%);
  pointer-events: none;
}

.half-transparent {
  position: relative;
  background-color: white;
  overflow: hidden;
}

.half-transparent::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, white 10%, transparent 90%);
}
/* ------------------------------------------------------------ */

.adp-document-card-skelton-wrapper {
  margin-bottom: 16px;
}

.show-all-button {
  border: 1px solid $color-gray400;
  background-color: $color-white;
  border-radius: 4px;
  padding: 9px, 16px;
  margin: 0 auto;
  position: relative;
  top: -80px;
}

.generate-contents {
  &__header {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 44px;
  }

  &__ai-spark-icon {
    fill: $color-gray1000;
  }
}
</style>
