<script setup lang="ts">
import { useRouter } from 'vue-router';
import Header from '@/components/layouts/header.vue';
import SurveyInput from '@/components/survey/input/survey-input.vue';
import { SurveyInputValue } from '@/types';

const router = useRouter();

const startSurvey = ({ question, docTypes }: SurveyInputValue) => {
  // TODO: survey（スレッド）を新規作成して、そのUUIDを取得するAPIを叩く
  // ↑のUUIDを使って、surveySessionに遷移する (スレッドに紐づけてデータを保存していくために必要)

  // 入力値のクエリパラメータをqueryにしていないのは、ヘッダーの検索窓の値でqueryを使用しており、そちらにも値が入ってしまうため
  router.push({
    name: `surveySession`,
    params: { survey_session_id: 'new' }, // v1ではデータを保存しないため、仮の値 ("new") を入れる
    query: { question, docTypes },
  });
};
</script>

<template>
  <div class="content-wrapper">
    <Header title="要約" header-width="100%" />
    <div class="content">
      <p class="c-title c-title--l">なんでも質問してください。</p>
      <p class="c-title c-title--l">
        ニュースや論文、社内文書から要約を作成します。
      </p>
      <SurveyInput :enable-transform-input="false" @submit="startSurvey" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.content-wrapper {
  width: 100%;
  margin: -24px 0 0 0;

  .content {
    padding-top: 32px;
    max-width: 912px;
    margin: 0 auto;
  }
}
</style>
