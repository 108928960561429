<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import api from '@/api';
import { DgrIcon } from '@stockmarkteam/donguri-ui';
import SharepointCredentialEditModal from '@/components/admin/user-documents/sharepoint-credential-edit-modal.vue';
import SharepointSiteEditModal from '@/components/admin/user-documents/sharepoint-site-edit-modal.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import AdminContent from '@/components/layouts/admin-content.vue';
import Header from '@/components/layouts/header.vue';
import { formatDateForStorage } from '@/utils/formatters';
import { checkMoveToAdmin } from '@/utils/user';
import { useBox } from '@/utils/user-document/box';
import { useSharepoint } from '@/utils/user-document/sharepoint';
import { StorageDetails, StorageStatus } from '@/utils/user-document/types';
import { useStore } from '@/utils/vue';
import AdminBaseTable, { Field } from '../admin-base-table.vue';
import { featureFlags } from '@/featureFlags';

type AdminTableItem = {
  id: number;
  name: string;
  connectText: string;
} & StorageDetails;

const router = useRouter();
const route = useRoute();
const store = useStore();
const { createSnackbar } = useSnackbar();

const {
  connect: connectBox,
  disconnect: disconnectBox,
  updateDetails: updateDetailsBox,
} = useBox();
const {
  connect: connectSharepoint,
  disconnect: disconnectSharepoint,
  updateDetails: updateDetailsSharepoint,
} = useSharepoint();

// for sharepoint
const isSharepointCredentialEditModalOpen = ref(false);
const isSharepointSiteEditModalOpen = ref(false);
const isDisabledSharepointSubmit = ref(false);

const isShowHeader = computed(() => items.value.some(i => i.isConnected));
const fields = computed<Field<AdminTableItem>[]>(() => [
  {
    name: 'name',
    displayName: 'ストレージ名',
    isSortable: false,
    type: 'string',
    fieldPosition: 'left',
    cellPosition: 'left',
  },
  {
    name: 'isConnected',
    displayName: 'ストレージの認証',
    isSortable: false,
    type: 'string',
    fieldPosition: 'left',
    cellPosition: 'left',
  },
  {
    name: 'fileCount',
    displayName: isShowHeader.value ? '同期済みのファイル数' : '',
    isSortable: false,
    type: 'string',
    fieldPosition: 'left',
    cellPosition: 'left',
  },
  {
    name: 'syncStartedAt',
    displayName: isShowHeader.value ? '同期開始時刻' : '',
    isSortable: false,
    type: 'string',
    fieldPosition: 'left',
    cellPosition: 'left',
  },
  {
    name: 'syncEndedAt',
    displayName: isShowHeader.value ? '同期終了時刻' : '',
    isSortable: false,
    type: 'string',
    fieldPosition: 'left',
    cellPosition: 'left',
  },
  {
    name: 'actions',
    displayName: '',
    isSortable: false,
    type: 'string',
    fieldPosition: 'right',
    cellPosition: 'right',
  },
]);

const items = ref<AdminTableItem[]>([
  {
    id: 1,
    name: 'BOX',
    isLoaded: false,
    isConnected: false,
    fileCount: '-',
    syncStartedAt: '-',
    syncEndedAt: '-',
    connectText: 'BOXを認証',
    status: 'NOT_CONNECTED',
  },
  {
    id: 2,
    name: 'SharePoint',
    isLoaded: false,
    isConnected: false,
    fileCount: '-',
    syncStartedAt: '-',
    syncEndedAt: '-',
    connectText: 'SharePointを認証',
    status: 'NOT_CONNECTED',
  },
]);

const preventMouseWheelEvent = (event: WheelEvent) => {
  event.preventDefault();
};

const actionMenuItem = ref<AdminTableItem | undefined>(undefined);

const closeActionMenu = () => {
  document.removeEventListener('wheel', preventMouseWheelEvent);
  actionMenuItem.value = undefined;
};

onBeforeUnmount(() => {
  document.removeEventListener('click', closeActionMenu);
  document.removeEventListener('wheel', preventMouseWheelEvent);
});

onMounted(async () => {
  checkMoveToAdmin(router);
  // 社内情報オプションチェック
  const teamInfo = await api.fetchTeamInfo();
  // この画面のアクセス権限
  if (!teamInfo.enable_user_document) {
    router.push({ name: 'anewsHome' });
    createSnackbar({
      message: '権限がありません',
      type: 'error',
    });
  }
  // BOXの認証などで、リダイレクトでエラーを表示させる場合
  const { error, ...query } = route.query;
  if (error === 'box_validation_error') {
    createSnackbar({
      message: '認証情報の登録に失敗しました',
      type: 'error',
    });
    // リロードの時、エラーを表示しないようにする
    router.replace({ query });
  }
  document.addEventListener('click', closeActionMenu);
  // 連携状態を更新
  items.value.forEach(async item => {
    switch (item.name) {
      case 'BOX':
        updateDetailsBox(item);
        break;
      case 'SharePoint':
        updateDetailsSharepoint(item);
        break;
      default:
        break;
    }
  });
});

const connectHandler = (item: AdminTableItem) => {
  switch (item.name) {
    case 'BOX':
      connectBox(item);
      break;
    case 'SharePoint':
      connectSharepoint(item);
      break;
    default:
      break;
  }
};

const ACTION_BLOCKED_STATUSES: StorageStatus[] = ['SYNCING', 'FETCH_ERROR'];
const disconnectHandler = (item: AdminTableItem) => {
  let action: () => void = () => undefined;
  switch (item.name) {
    case 'BOX':
      action = disconnectBox(item);
      break;
    case 'SharePoint':
      action = disconnectSharepoint(item);
      break;
    default:
      break;
  }

  const payload = {
    headerText: 'ストレージの認証解除',
    bodyText:
      'ストレージの認証を解除しますか？認証を解除するとAnewsで社内情報を検索できなくなります。',
    action,
    btnText: '解除',
    isBtnDisabled: ACTION_BLOCKED_STATUSES.includes(item.status),
    warningText: ACTION_BLOCKED_STATUSES.includes(item.status)
      ? '現在はストレージの認証を解除できません'
      : '',
  };
  store.commit('confirmationModal/showConfirmationModal');
  store.commit('confirmationModal/setTextAndAction', payload);
};

const editSharepointCredentialHandler = async (item: AdminTableItem) => {
  switch (item.name) {
    case 'SharePoint':
      isSharepointCredentialEditModalOpen.value = true;
      isDisabledSharepointSubmit.value = ACTION_BLOCKED_STATUSES.includes(
        item.status,
      );
      break;
    default:
      break;
  }
};

const editSharepointSiteHandler = async (item: AdminTableItem) => {
  switch (item.name) {
    case 'SharePoint':
      isSharepointSiteEditModalOpen.value = true;
      isDisabledSharepointSubmit.value = ACTION_BLOCKED_STATUSES.includes(
        item.status,
      );
      break;
    default:
      break;
  }
};

const clickMenu = (item: AdminTableItem) => {
  actionMenuItem.value = item;
  // メニューがズレるのを防ぐため、スクロールを禁止
  document.addEventListener('wheel', preventMouseWheelEvent, {
    passive: false,
  });
};

const actions = [
  {
    clickableStorageTypes: ['BOX', 'SharePoint'],
    name: 'ストレージの認証解除',
    func: disconnectHandler,
  },
  {
    clickableStorageTypes: ['SharePoint'],
    name: 'ストレージの認証情報編集',
    func: editSharepointCredentialHandler,
  },
  {
    clickableStorageTypes: ['SharePoint'],
    name: 'サイトを編集',
    func: editSharepointSiteHandler,
  },
];
const displayStorageStatus = (item: AdminTableItem) =>
  isUncertified(item) ? '未認証' : '認証済み';

const isUncertified = (item: AdminTableItem) =>
  !item.isConnected || item.status === 'CREDENTIAL_INVALID';

const ANDEV_5237_DISABLE_BUTTON_IN_SHAREPOINT_SYNC =
  featureFlags.ANDEV_5237_DISABLE_BUTTON_IN_SHAREPOINT_SYNC;
</script>

<template>
  <div class="user-documents-admin">
    <Header title="社内情報管理" header-width="100%"></Header>
    <AdminContent>
      <div class="o-user-documents-admin__content">
        <div class="spacing-16"></div>
        <div class="title">
          <span class="c-title c-title--m">ストレージ</span>
        </div>
        <div class="spacing-16"></div>
        <div class="o-user-documents-table">
          <AdminBaseTable
            :is-sticky-header="true"
            :fields="fields"
            :items="items"
            class="admin-base-table"
          >
            <template #header-fileCount="{ item }">
              <div class="header-title" v-if="isShowHeader">
                <span>{{ item.displayName }}</span>
                <div class="spacing-08"></div>
                <DgrIcon
                  size="small"
                  name="question-circle"
                  v-tooltip.top="{
                    content:
                      '連携中のファイルはPDF/Word/PowerPointのみとなります',
                    theme: 'menu',
                  }"
                />
              </div>
            </template>
            <template #cell-name="{ item }">
              <DgrIcon
                :keep-fill="false"
                name="chart-round-bubble"
                v-if="item.status === 'SYNCING'"
                class="name-icon syncing"
              />
              <DgrIcon
                :keep-fill="false"
                name="check-circle"
                v-else-if="item.status === 'SYNCED'"
                class="name-icon synced"
              />
              <DgrIcon
                :keep-fill="false"
                name="exclamation-circle"
                v-else-if="item.status === 'SYNC_FAILED'"
                class="name-icon sync-failed"
                v-tooltip.top="{
                  content:
                    item.status === 'SYNC_FAILED'
                      ? '同期中にエラーが発生しました。現在調査中ですので解消までお待ちください。'
                      : '',
                  theme: 'menu',
                }"
              />
              <span v-text="item.name"></span>
            </template>
            <template #cell-isConnected="{ item }">
              <span v-text="displayStorageStatus(item)"></span>
            </template>
            <template #cell-fileCount="{ item }">
              <span v-text="isShowHeader ? item.fileCount : ''"></span>
            </template>
            <template #cell-syncStartedAt="{ item }">
              <span
                v-text="
                  isShowHeader ? formatDateForStorage(item.syncStartedAt) : ''
                "
              ></span>
            </template>
            <template #cell-syncEndedAt="{ item }">
              <span
                v-text="
                  isShowHeader ? formatDateForStorage(item.syncEndedAt) : ''
                "
              ></span>
            </template>
            <template #cell-actions="{ item }">
              <div v-if="isUncertified(item)">
                <div class="action-connect">
                  <button
                    class="action-button c-outlineBtn c-btn--auto c-btn--small c-outlineBtn--secondary"
                    :class="{ disabled: !item.isLoaded }"
                    :disabled="!item.isLoaded"
                    @click="connectHandler(item)"
                    v-text="item.connectText"
                  ></button>
                  <div
                    class="connect-failed"
                    v-if="item.status === 'CONNECT_FAILED'"
                  >
                    認証情報の取得に失敗しました。再度やり直してください。
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="actions">
                  <div
                    data-testid="action-icon"
                    class="action-icon"
                    @click.stop="clickMenu(item)"
                  >
                    <DgrIcon name="ellipsis-h" />
                  </div>
                  <div
                    class="action-menu"
                    :class="{ open: actionMenuItem?.id === item.id }"
                    v-if="actionMenuItem?.id === item.id"
                  >
                    <div
                      class="item-container"
                      v-for="action in actions"
                      :key="action.name"
                    >
                      <div
                        v-if="action.clickableStorageTypes.includes(item.name)"
                        class="item c-text c-text--m"
                        @click="action.func ? action.func(item) : undefined"
                      >
                        {{ action.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </AdminBaseTable>
        </div>
      </div>
    </AdminContent>
    <SharepointCredentialEditModal
      :is-open="isSharepointCredentialEditModalOpen"
      :is-disabled="
        isDisabledSharepointSubmit &&
        ANDEV_5237_DISABLE_BUTTON_IN_SHAREPOINT_SYNC
      "
      @on-close="() => (isSharepointCredentialEditModalOpen = false)"
    />
    <SharepointSiteEditModal
      :is-open="isSharepointSiteEditModalOpen"
      :is-disabled="
        isDisabledSharepointSubmit &&
        ANDEV_5237_DISABLE_BUTTON_IN_SHAREPOINT_SYNC
      "
      @on-close="() => (isSharepointSiteEditModalOpen = false)"
    />
  </div>
</template>

<style lang="scss" scoped>
.user-documents-admin {
  width: 100%;
  margin: -24px 0 0 0;
  padding: 0 !important;

  .o-user-documents-admin__content {
    padding-bottom: 280px;
  }

  .name-icon {
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;

    &.syncing {
      fill: $color-gray800;
    }
    &.synced {
      fill: $color-green600;
    }
    &.sync-failed {
      fill: $color-orange1000;
    }
  }

  .header-title {
    display: flex;
  }

  .action-connect {
    .action-button {
      min-width: 110px;
      margin-right: 0;
      margin-left: auto;
    }
    .connect-failed {
      text-align: right;
      color: $color-orange1000;
    }
  }

  .actions {
    position: relative;
    width: 40px;
    padding-right: 0;
    display: inline-block;

    .action-icon {
      display: flex;
      justify-content: center;
      width: 32px;
      padding: 8px;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: #f2f2f2;
      }
    }

    .action-menu {
      position: fixed;
      right: 0;
      min-width: 140px;
      word-wrap: nowrap;
      white-space: nowrap;
      padding: 12px 0;
      background: #ffffff;
      box-shadow: 0px 1px 5px rgba(74, 74, 74, 0.25);
      border-radius: 4px;
      z-index: var(--z-action-menu);

      .item {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 4px 12px;
        &:hover {
          background: #e6e6e6;
        }
        &.disabled {
          color: #b3b3b3;
          cursor: default;
        }
        .icon {
          margin-right: 4px;
        }
      }
    }
  }
}
</style>
