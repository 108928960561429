<script setup lang="ts">
/**
 * スレッドを表すコンポーネント
 */
import { computed, onMounted, provide, ref } from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api';
import { marked } from 'marked';
import sanitizeHtml from 'sanitize-html';
import Header from '@/components/layouts/header.vue';
import SurveyInput from '@/components/survey/input/survey-input.vue';
import SurveyHistory from '@/components/survey/survey-history.vue';
import {
  AdpDocument,
  DocType,
  Part,
  SurveyHistoryData,
  SurveyInputValue,
  UserDocument,
} from '@/types';
import { closeAllDocumentsKey } from '@/utils/injectionKeys';
import { sanitize } from '@/utils/sanitize';
import { processNode } from '@/utils/survey/domParser';
import { useTeamInfo } from '@/utils/swr';

const route = useRoute();

/**
 * サニタイズの設定
 */
const MarkdownToHtmlOptions = {
  allowedTags: sanitizeHtml.defaults.allowedTags, // 'img'タグは含まれていない
  allowedAttributes: {
    ...sanitizeHtml.defaults.allowedAttributes,
    a: ['href', 'target', 'style', 'class'],
  },
};

/* API関連のstate */
const surveyHistories = ref<Array<SurveyHistoryData>>([]);
const sources = ref<Array<AdpDocument | UserDocument>>([]); // 要約生成のためにモデルに渡したソース
let originMarkdown = ''; // 1行単位のレスポンスデータ (マークダウン形式の文字列) が追加されていく元データ
const contentParts = ref<Part[]>([]); // サーバから返ってきたマークダウン形式の要約をパースしたもの

/* 要約関連のstate */
const question = ref<string>('');
const docTypes = ref<Array<DocType | 'user_document'>>([]);
const { data: teamInfo } = useTeamInfo();
const langs = computed(() =>
  teamInfo.value?.enable_technical_literature ? ['ja', 'en'] : ['ja'],
);

/* 表示に関するstate */
const isLoadingSources = ref<boolean>(true); // ソース (検索結果) のローディング
const isLoadingSummary = ref<boolean>(true); // 要約のローディング
const isInProgressSurvey = ref<boolean>(true); // スレッド (survey-session.vue)内 でSurveyを実行中かどうか
const isClosingAllDocuments = ref(false);

const startSurvey = async ({
  question: emitQuestion,
  docTypes: emitDocTypes,
}: SurveyInputValue) => {
  question.value = emitQuestion;
  docTypes.value = emitDocTypes;

  await submitSurveyStream(true);
};

/**
 * Surveyの実行開始時の初期化処理
 */
const initializeSurvey = () => {
  isLoadingSources.value = true;
  isLoadingSummary.value = true;
  isInProgressSurvey.value = true;
  originMarkdown = '';
  sources.value = [];
  contentParts.value = [];
  isClosingAllDocuments.value = true; // 新たに要約を生成する際、"すべて表示"で展開しているスレッド内のすべてのhistoryのドキュメントを折りたたむ
};

/**
 * ドキュメントの更新
 * `src/components/survey/document/survey-adp-document-card.vue`でマークを行うとemitで呼び出される
 *
 * @param {number} historyIndex - surveyHistoriesのindex
 * @param {number} sourceIndex - sourcesのindex
 * @param {AdpDocument} document - 更新するドキュメント
 */
const updateDocument = (
  historyIndex: number,
  sourceIndex: number,
  document: AdpDocument,
) => {
  // NOTE: 表示に関わる値の変更は`updateSurveyHistory`関数に責務を持たせたいので、ここでは直接更新はせず一時的な変数を用意
  const updatedSources = [...surveyHistories.value[historyIndex].sources];
  updatedSources[sourceIndex] = document;

  updateSurveyHistory(historyIndex, { sources: updatedSources });
};

/**
 * 特定のインデックスの要素を更新する関数
 * @param {number} index - 更新する要素のインデックス
 * @param {Partial<SurveyHistoryData>} updates - 更新内容のオブジェクト
 */
const updateSurveyHistory = (
  index: number,
  updates: Partial<SurveyHistoryData>,
) => {
  if (index >= 0 && index < surveyHistories.value.length) {
    surveyHistories.value[index] = {
      ...surveyHistories.value[index],
      ...updates,
    };
  }
};

/**
 * Surveyを実行 ( 「questionで検索を実行し、その結果をもとに要約を生成」を行うAPI呼び出しと、レスポンスのハンドリング )
 * @param {boolean} isNew - 新規surveyかどうか (v1以降の機能で、過去のquestionを編集して再度surveyを実行する想定があるため最初から対応)
 * @param {number} index - surveyHistoriesのindex (isNewがfalseの場合、過去のsurveyを編集するために使用する該当データのindex)
 */
const submitSurveyStream = async (isNew: boolean, index?: number) => {
  // 初期化
  initializeSurvey();
  if (isNew) {
    surveyHistories.value.push({
      question: question.value,
      sources: [],
      summary: [],
      isNoHitSources: false,
      isError: false,
    });
  }
  let surveyIndex = index ?? surveyHistories.value.length - 1;

  for await (let chunk of api.postSurvey(
    question.value,
    docTypes.value,
    langs.value,
    [],
  )) {
    if (chunk === undefined) continue;
    if (chunk.type === 'error') {
      updateSurveyHistory(surveyIndex, { isError: true });
      isInProgressSurvey.value = false;
      continue;
    }
    // 検索結果が0件
    if (chunk.type === 'no_hit_sources') {
      updateSurveyHistory(surveyIndex, { isNoHitSources: true });
      isInProgressSurvey.value = false;
      continue;
    }
    // ソース取得完了 (ソース部分だけ先に描画)
    if (chunk.type === 'sources_done') {
      updateSurveyHistory(surveyIndex, { sources: sources.value });
      isLoadingSources.value = false; // inputの"生成"ボタンを有効化
      continue;
    }

    if (chunk.type === 'source') {
      sources.value.push(chunk);
    } else if (chunk.type === 'token') {
      isLoadingSummary.value = false; // 要約のローディング終了

      originMarkdown += chunk.content;
      const markdownToHtml = marked.parse(originMarkdown) as string; // ネストした箇条書きなどを考慮するため、追加された行だけではなく全量を parse する
      const sanitizedHtml = sanitize(markdownToHtml, MarkdownToHtmlOptions);
      const parser = new DOMParser();
      const doc = parser.parseFromString(sanitizedHtml, 'text/html');
      const { processedParts } = processNode(doc.body, 0, 1, sources.value);
      contentParts.value = processedParts;
      updateSurveyHistory(surveyIndex, { summary: contentParts.value });
    }
  }
  isInProgressSurvey.value = false;
  isClosingAllDocuments.value = false;
};

provide(closeAllDocumentsKey, isClosingAllDocuments);

onMounted(async () => {
  // TODO: スレッド(surveysテーブル)に紐づく(survey_historiesの)データを取得 (ページング処理必須)
  // ※ 1度もsurveyを実行していなければデータなし

  // top画面から新規でスレッドを作成した場合のみ、queryとdocTypesをクエリパラメータから取得
  if (surveyHistories.value.length === 0) {
    question.value = route.query.question as string;
    const docTypesQueryParam = Array.isArray(route.query.docTypes)
      ? route.query.docTypes
      : [route.query.docTypes];
    docTypes.value =
      (docTypesQueryParam as Array<DocType | 'user_document'>) ?? [];

    await submitSurveyStream(true);
  }
});
</script>

<template>
  <div class="survey-session-container">
    <Header title="要約結果" header-width="100%" />
    <div class="content-wrapper">
      <div
        class="content"
        v-for="(history, index) in surveyHistories"
        :key="index"
      >
        <SurveyHistory
          :question="history.question"
          :sources="history.sources"
          :summary="history.summary"
          :is-no-hit-sources="history.isNoHitSources"
          :is-error="history.isError"
          @update-document="
            ({ sourceIndex, document }) =>
              updateDocument(index, sourceIndex, document)
          "
        />
      </div>

      <div class="input-wrapper">
        <SurveyInput
          :enable-transform-input="true"
          :is-loading="isInProgressSurvey"
          @submit="startSurvey"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.survey-session-container {
  width: 100%;
  margin: -24px 0 0 0;

  .content-wrapper {
    max-width: 912px;
    margin: 0 auto;
    margin-bottom: 72px; // 入力欄 (survey-input.vue) にフォーカスが当たっている時に、下の方の文字が見えないので余白をとる
  }
}
</style>
