export const isDev = import.meta.env.VITE_ENV === 'development';
const isTest = import.meta.env.VITE_ENV === 'test';

// flagの内容
// false: 開発中
// true: リリース
const flags = {
  ANDEV_4571_ADD_GROUP_TAG_LIST: true,
  ANDEV_4635_ADD_CATEGORY_INFORMATION: true,
  ANDEV_4926_TOOLTIP_XSS: false,
  ANDEV_4747_IMAGE_CAPTION_SUMMARY: false,
  ANDEV_4946_SIMILAR_DOCUMENTS_AGGREGATION: true,
  ANDEV_4798_SURVEY_V1: false,
  ANDEV_5237_DISABLE_BUTTON_IN_SHAREPOINT_SYNC: false,
  ANDEV_5252_FOLLOW_LIST_MOBILE_DELETE_MARK_NEWS: false,
};

const isKey = (x: string): x is keyof typeof flags => x in flags;
Object.keys(flags).forEach(key => {
  if (!isKey(key)) return;
  flags[key] = isDev || isTest || flags[key];
});

export const featureFlags = flags;
