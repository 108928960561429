<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import api from '@/api';
import { DOC_TYPE_LABELS } from '@/constants';
import AdpDocumentMenu from '@/components/adp-document/actions/adp-document-menu.vue';
import AdpDocumentCardLink from '@/components/common/adp-document/adp-document-card-link.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import {
  AdpDocument,
  AdpDocumentOptionAction,
  isArticle,
  isResearchPaper,
} from '@/types';
import { getPublisher } from '@/utils/adpDocument';
import { imageErrorHandler } from '@/utils/errorHandler';
import { formatDate } from '@/utils/formatters';
import { useEmitter } from '@/utils/vue';

interface Props {
  adpDocument: AdpDocument & { is_alliance_media?: boolean };
  badgeNumber: number;
  userEnableTranslateTitle: boolean;
  isNarrow: boolean; // 文字が溢れた際に`...`で表示するかどうかのフラグ
}

const props = defineProps<Props>();

const emit = defineEmits<{
  'update-document': [AdpDocument];
}>();

const store = useStore();
const route = useRoute();
const emitter = useEmitter();
const { createSnackbar } = useSnackbar();

/**
 * Local States
 */

const userInfo = computed(() => store.state.userInfo.userInfo);

const hitKeywords = computed(() => {
  const keywords = [
    ...(props.adpDocument?.hit_keywords ?? []),
    ...(props.adpDocument?.synonym_keywords ?? []),
  ].filter(keyword => keyword.length > 0);
  return keywords;
});

const enableTranslateTitle = computed(
  () =>
    userInfo.value?.enable_translate_article_title &&
    props.adpDocument?.lang !== 'ja' &&
    !!props.adpDocument?.translated_title,
);

const adpDocumentTitle = computed(() =>
  enableTranslateTitle.value
    ? props.adpDocument?.translated_title
    : props.adpDocument?.title,
);

const isPdf = computed(() => {
  switch (props.adpDocument.doc_type) {
    case 'article':
    case 'patent':
    case 'research_paper':
      return props.adpDocument?.pdf_url;
    default:
      return false;
  }
});

const isMarked = computed(() => {
  if (!userInfo.value) return false;
  return props.adpDocument.marks.some(
    mark => mark.user_id === userInfo.value.id,
  );
});

const markedUsers = computed(() => {
  return props.adpDocument.marks.map(mark => ({
    id: mark.user_id,
    name: mark.user_name,
  }));
});

const markedGroups = computed(() =>
  props.adpDocument.group_marks.map(groupMark => ({
    id: groupMark.group_id,
    name: groupMark.group_name,
  })),
);

const commentedUsers = computed(() =>
  props.adpDocument.comments.map(comment => ({
    id: comment.user_id,
    name: comment.user_name,
    commentId: comment.id,
  })),
);

const optionActions: Array<AdpDocumentOptionAction> = [
  {
    label: 'リンクをコピー',
    icon: 'chain',
    action: async () => {
      await copyLinkUrl();
    },
  },
];

/**
 * Handlers
 */

const setupModal = () => {
  store.commit('modal/setArticle', props.adpDocument);
};

const updateIsRead = () => {
  if (!props.adpDocument) return;
  emitter.emit('article-updated', { ...props.adpDocument, is_read: true });
};

const handleCommentButton = () => {
  setupModal();
  store.commit('modal/showModal', 'commentCreate');
};

const handleGroupMarkButton = () => {
  setupModal();
  store.commit('modal/showModal', 'groupMark');
};

const handlePopupMenu = () => {
  setupModal();
  store.commit('modal/showModal', 'mark');
};

const handleMarkButton = async () => {
  if (!userInfo.value) return;

  if (isMarked.value) {
    await removeMark();
  } else {
    await createMark();
  }
};

const createMark = async () => {
  let document: AdpDocument;
  try {
    await api.sendMark(props.adpDocument, null, undefined, {
      pageName: 'survey',
      pageUrl: route.fullPath,
      feature: 'survey',
      feedType: 'all',
    });
    document = await api.fetchDocument(
      props.adpDocument.id,
      props.adpDocument.doc_type,
    );
  } catch (e) {
    createSnackbar({
      message: 'マークできませんでした',
      type: 'error',
    });
    throw e;
  }

  emit('update-document', document);
  createSnackbar({
    type: 'link',
    message: 'マイページ',
    params: {
      link: `/users/${userInfo.value?.id}`,
      suffixText: 'にマークしました',
    },
  });
};

const removeMark = async () => {
  const myMark = props.adpDocument.marks.find(
    mark => mark.user_id === userInfo.value.id,
  );
  if (!myMark) return;

  let document: AdpDocument;
  try {
    await api.deleteMark(myMark.id);
    document = await api.fetchDocument(
      props.adpDocument.id,
      props.adpDocument.doc_type,
    );
  } catch (e) {
    createSnackbar({
      message: 'マークを取り消せませんでした',
      type: 'error',
    });
    throw e;
  }
  emit('update-document', document);

  createSnackbar({
    message: 'マークを取り消しました',
    type: 'success',
    undoFunc: async () => {
      if (!props.adpDocument) return;
      await createMark();
    },
  });
};

const copyLinkUrl = async () => {
  try {
    // NOTE: 既存のコピー機能で使用している Clipboard JS は非推奨の`document.execCommand`を使用しているため、使わない
    // クリップボード API: https://developer.mozilla.org/ja/docs/Web/API/Clipboard_API
    await navigator.clipboard.writeText(props.adpDocument.url);
    createSnackbar({
      message: 'リンクをコピーしました',
      type: 'success',
    });
  } catch (_e) {
    createSnackbar({
      message: 'リンクをコピーできませんでした',
      type: 'error',
    });
  }
};
</script>

<template>
  <div
    class="document-card"
    :class="{ 'document-card--narrow': isNarrow }"
    ref="adpDocumentCardRef"
    v-if="adpDocument"
  >
    <VTooltip placement="top" :disabled="hitKeywords.length === 0">
      <div class="document-card__body">
        <div class="document-card__info">
          <div class="document-card__header c-text c-text--s">
            <span class="document-card__doctype c-title c-title--xs"
              >{{ badgeNumber }}.{{
                DOC_TYPE_LABELS[adpDocument.doc_type]
              }}</span
            >
            <span class="document-card__publisher">{{
              getPublisher(adpDocument)
            }}</span>
            <VTooltip
              placement="bottom"
              :disabled="!isResearchPaper(props.adpDocument)"
            >
              <span
                v-if="
                  isResearchPaper(props.adpDocument) &&
                  props.adpDocument.is_priority_journal
                "
                class="document-card__priority-journal c-text c-text--xs"
                >SJR</span
              >
              <template #popper>
                <div>
                  <a
                    class="help-link"
                    href="https://anews-stockmark.zendesk.com/hc/ja/articles/13717202977689-%E8%AB%96%E6%96%87%E6%A9%9F%E8%83%BD%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6"
                    target="_blank"
                    rel="noopener noreferrer"
                    >SJRスコア</a
                  >の上位に入る影響度の高い学術雑誌です
                </div>
              </template>
            </VTooltip>
            <div
              v-if="
                isArticle(props.adpDocument) && props.adpDocument.login_required
              "
              class="document-card__media-type-label c-title c-title--xs"
            >
              ログインが必要
            </div>
            <div
              v-else-if="props.adpDocument.is_alliance_media"
              class="document-card__media-type-label c-title c-title--xs"
            >
              Anewsで読める有料記事
            </div>
          </div>
          <div class="document-card__title c-text c-text--xm">
            <AdpDocumentCardLink
              v-bind="{ adpDocument, pageName: 'survey' }"
              :class="{ read: adpDocument.is_read, 'is-pdf': isPdf }"
              @article-clicked="updateIsRead"
              >{{ adpDocumentTitle }}</AdpDocumentCardLink
            >
          </div>
          <div
            v-if="
              props.userEnableTranslateTitle &&
              adpDocument.lang !== 'ja' &&
              adpDocument.translated_title
            "
            class="document-card__original-title c-text c-text--s"
          >
            <AdpDocumentCardLink v-bind="{ adpDocument, pageName: 'survey' }"
              >元タイトル：{{
                adpDocument.translated_title
              }}</AdpDocumentCardLink
            >
          </div>
          <div
            v-if="isPdf && isResearchPaper(adpDocument)"
            class="document-card__provided-site document-card__info__no-gap c-text c-text--s"
          >
            <AdpDocumentCardLink
              v-bind="{ adpDocument, usePdfUrl: false, pageName: 'survey' }"
              >提供サイトで見る</AdpDocumentCardLink
            >
          </div>
          <div class="document-card__date c-text c-text--s">
            {{ formatDate(adpDocument.published_at) }}
          </div>
        </div>
        <div class="document-card__image">
          <AdpDocumentCardLink v-bind="{ adpDocument, pageName: 'survey' }">
            <img
              :src="adpDocument.image_url ?? 'noImage1to1'"
              @error="imageErrorHandler($event, '1to1')"
              loading="lazy"
            />
          </AdpDocumentCardLink>
        </div>
      </div>
      <template #popper>
        <span>含むキーワード</span>
        <div v-for="(keyword, keywordIndex) in hitKeywords" :key="keywordIndex">
          ・{{ keyword }}
        </div>
      </template>
    </VTooltip>
    <div class="document-card__user-action">
      <AdpDocumentMenu
        :is-marked="isMarked"
        :mark-users="markedUsers"
        :marked-groups="markedGroups"
        :comment-users="commentedUsers"
        :option-actions="optionActions"
        :layout-theme="'style-start'"
        @handle-popup-menu-click="handlePopupMenu"
        @handle-mark-click="handleMarkButton"
        @handle-group-mark-click="handleGroupMarkButton"
        @handle-comment-click="handleCommentButton"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.document-card {
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 16px;
  background-color: white;
  border: 1px solid #e6e6e6;
  border-radius: 4px;

  &--narrow {
    grid-template-columns: 1fr;
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 16px;
  }

  &__info {
    display: grid;
    gap: 4px;
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__doctype {
    background-color: $color-gray1000;
    color: $color-white;
    padding: 4px;
    margin-right: 6px;
    border-radius: 2px;
    font-weight: 700;
  }

  &__publisher {
    color: $color-gray800;
  }

  &__priority-journal {
    align-self: center;
    height: 16px;
    margin-left: 2px;
    padding: 0 2px;
    color: $color-white;
    background: #e77642;
  }

  &__media-type-label {
    margin-left: 4px;
    padding: 1px 8px;
    color: $color-gray800;
    background-color: $color-gray200;
    white-space: nowrap;
    border-radius: 2px;
  }

  &__title {
    color: $color-gray1000;
    line-height: 22px;
  }

  &__original-title {
    // aタグに`color: #4a4a4a;`のスタイルが当たっていて上書きされている
    a {
      color: $color-gray800;
    }
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    margin: 2px 0 4px;
  }

  &__provided-site {
    text-decoration: underline;
    cursor: pointer;
  }

  &__date {
    color: $color-gray800;
  }

  &__image {
    img {
      width: 80px;
      height: 80px;
      aspect-ratio: 1 / 1;
      object-fit: cover;
      object-position: 50% 25%;
      border-radius: 4px;
    }
  }

  &__user-action {
    margin-top: 8px;
  }
}
</style>
