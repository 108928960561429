<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api';
import dayjs from 'dayjs';
import ItemList from '@/components/menu/common/item-list.vue';
import MediaTypeSelection from '@/components/menu/media-type-selection.vue';
import SectionWrapper from '@/components/menu/sections/section-wrapper.vue';
import { FeedType, Group, KeywordFeed, Theme, ThemeList } from '@/types';
import {
  useGroups,
  useTeamInfo,
  useThemeKeywordFeedCounts,
  useUserInfo,
} from '@/utils/swr';
import { userSession } from '@/utils/userSession';
import { useEmitter, useStore } from '@/utils/vue';
import { featureFlags } from '@/featureFlags';

type ItemListProps = InstanceType<typeof ItemList>['$props'];
type ItemsProps = ItemListProps['items'];
type ItemProps = ItemsProps extends (infer U)[] ? U : ItemsProps;

const store = useStore();
const emitter = useEmitter();
const route = useRoute();
const { data: teamInfo } = useTeamInfo();
const { data: userInfo } = useUserInfo();

const feedType = computed(() => store.state.feedType.feedType);

const memberThemes = computed<ThemeList[]>(
  () => store.getters['themeList/memberThemes'],
);
const { data: themeKeywordFeedCounts } = useThemeKeywordFeedCounts(feedType);
const mediaTypeSelectionEnabled = computed(
  () => teamInfo.value?.enable_foreign_lang_media,
);

const onFeedTypeSelect = async ({ option }: { option: FeedType }) => {
  if (feedType.value === option) return;

  await store.dispatch('feedType/updateFeedType', option);
  await store.dispatch('themeList/fetchThemeList', option);
};

const { data: groups } = useGroups();

const items = computed(() => {
  const items: ItemsProps = [
    {
      id: 'home',
      name: 'トップ',
      route: { name: 'anewsHome' },
      isSelectedExactRoute: true,
    },
    {
      id: 'personal',
      name: 'パーソナルニュース',
      route: { name: 'personalNews' },
      isSelected: [
        'personalNews',
        'personalResearchPapers',
        'personalPatents',
      ].includes(
        route.name as string, // includesがstringしか受け取れないのでasで型を指定
      ),
    },
    {
      id: 'industry',
      name: '業界ニュース',
      route: { name: 'industryNews' },
    },
    {
      id: 'follow-lists',
      name: 'フォローリスト',
      route: { name: 'followLists' },
    },
    ...(featureFlags.ANDEV_5252_FOLLOW_LIST_MOBILE_DELETE_MARK_NEWS
      ? []
      : [
          {
            id: 'marks',
            name: '新着マークニュース',
            route: { name: 'teamMarks' },
          },
        ]),
    {
      id: 'search',
      name: 'テーマ検索',
      route: { name: 'themeSearch' },
      icon: 'search',
    },
    {
      id: 'create',
      name: 'テーマ作成',
      route: { name: 'themeCreate' },
      icon: 'plus',
    },
  ];
  return items;
});

const getThemeKeywordFeedCount = (themeId: number) =>
  themeKeywordFeedCounts.value?.theme_keyword_feed_counts[String(themeId)] ?? 0;

const getItemForTheme = (theme: ThemeList): ItemProps => ({
  id: theme.id,
  name: theme.name,
  route: {
    name: 'themeFeed',
    params: { themeId: String(theme.id) },
  },
  count: getThemeKeywordFeedCount(theme.id),
  icon: 'hashtag',
});

const favoriteThemes = computed(() => {
  return [
    ...memberThemes.value
      .filter(t => t.is_favorite)
      .sort((a, b) => a.order - b.order)
      .map(t => getItemForTheme(t)),
  ];
});

const getTheme = (id: number) => memberThemes.value.find(t => t.id === id);
const updateTheme = (theme: ThemeList) =>
  store.commit('themeList/updateTheme', theme);

const updateFavoriteThemeOrder = async ({
  to,
  items,
}: {
  from: number;
  to: number;
  items: ItemsProps;
}) => {
  const orderedThemeIds = items.map(i =>
    Number((i.route as { params: { themeId: string } }).params.themeId),
  );
  const movedTheme = getTheme(orderedThemeIds[to]);
  if (movedTheme) {
    movedTheme.order = to + 1;
    updateTheme(movedTheme);
    await api.updateFavoriteTheme(movedTheme.id, movedTheme.order);
  }
  orderedThemeIds.forEach((id, index) => {
    const t = getTheme(id);
    if (t && index !== to) {
      t.order = index + 1;
      updateTheme(t);
    }
  });
};

const notFavoriteThemes = computed(
  () => memberThemes.value.filter(t => !t.is_deleted && !t.is_favorite) ?? [],
);

const personalThemes = computed(() =>
  notFavoriteThemes.value
    .filter(
      t => t.access_scope === 'personal' && t.user_id === userInfo.value?.id,
    )
    .map(t => getItemForTheme(t)),
);

const themesByGroup = computed(() => {
  const result: [number, ItemProps[]][] = [];
  const groupThemes = notFavoriteThemes.value.filter(
    t => t.access_scope === 'group',
  );
  const gs = groups.value?.groups ?? [];
  if (groupThemes && gs) {
    const themeGroupIds = groupThemes.map(t => t.group_id ?? -1);
    const gIds = gs.map(g => g.id);
    themeGroupIds
      .filter((id, i) => themeGroupIds.indexOf(id) === i)
      .sort((a, b) => gIds.indexOf(a) - gIds.indexOf(b))
      .forEach(id => {
        result.push([
          id,
          groupThemes
            .filter(t => t.group_id === id)
            .map(t => getItemForTheme(t)),
        ]);
      });
  }
  return result;
});

const publicThemes = computed(() =>
  notFavoriteThemes.value
    .filter(t => t.access_scope === 'public')
    .map(t => getItemForTheme(t)),
);

const getGroupName = (groupId: Group['id']) => {
  const found = groups.value?.groups.find(g => g.id === groupId);
  if (!found) {
    return '';
  }
  return found.name;
};

emitter.on('theme-updated', async (theme: Theme) => {
  const t = getTheme(theme.id);
  if (t) {
    if (t.is_favorite && !theme.is_favorite) {
      await store.dispatch('themeList/fetchThemeList', feedType.value);
    } else {
      updateTheme(t);
    }
  } else {
    await store.dispatch('themeList/fetchThemeList', feedType.value);
  }
});

emitter.on(
  'article-removed',
  ({
    themeId,
    feedDate,
  }: {
    themeId: number | null;
    feedDate: KeywordFeed['feed_date'];
  }) => {
    if (themeId) {
      const nowDate = dayjs().format('YYYY-MM-DD');
      if (nowDate === feedDate) {
        if (themeKeywordFeedCounts.value) {
          themeKeywordFeedCounts.value.theme_keyword_feed_counts[
            String(themeId)
          ] = Math.max(0, getThemeKeywordFeedCount(themeId) - 1);
        }
      }
    }
  },
);
</script>

<template>
  <div class="left-menu-news" v-if="teamInfo && userInfo">
    <div v-if="mediaTypeSelectionEnabled" class="media-type-selection-wrapper">
      <MediaTypeSelection
        class="media-type-selection"
        :feed-type="feedType"
        @on-select="onFeedTypeSelect"
      ></MediaTypeSelection>
      <div class="spacing"></div>
    </div>
    <SectionWrapper :additional-height="mediaTypeSelectionEnabled ? 72 : 0">
      <ItemList :items="items" />
      <ItemList
        v-if="favoriteThemes && favoriteThemes.length > 0"
        title="お気に入り"
        :items="favoriteThemes"
        :is-foldable="true"
        :initial-is-open="userSession.getThemeListState('favorite', null)"
        @menu-list-is-open="
          isOpen => userSession.setThemeListState('favorite', null, isOpen)
        "
        :is-sortable="true"
        @menu-list-order-changed="updateFavoriteThemeOrder"
      />
      <ItemList
        v-if="personalThemes && personalThemes.length > 0"
        title="個人テーマ"
        :items="personalThemes"
        :is-foldable="true"
        :initial-is-open="userSession.getThemeListState('personal', null)"
        @menu-list-is-open="
          isOpen => userSession.setThemeListState('personal', null, isOpen)
        "
      />
      <template v-if="groups && themesByGroup && themesByGroup.length > 0">
        <ItemList
          v-for="[group_id, themes] in themesByGroup"
          :key="group_id"
          :title="getGroupName(group_id)"
          :items="themes"
          :is-foldable="true"
          :initial-is-open="userSession.getThemeListState('group', group_id)"
          @menu-list-is-open="
            isOpen => userSession.setThemeListState('group', group_id, isOpen)
          "
        />
      </template>
      <ItemList
        v-if="publicThemes && publicThemes.length > 0"
        title="全体テーマ"
        :items="publicThemes"
        :is-foldable="true"
        :initial-is-open="userSession.getThemeListState('public', null)"
        @menu-list-is-open="
          isOpen => userSession.setThemeListState('public', null, isOpen)
        "
      />
    </SectionWrapper>
  </div>
</template>

<style lang="scss" scoped>
.left-menu-news {
  .media-type-selection-wrapper {
    .media-type-selection {
      position: fixed;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 288px;
      height: 72px;
      padding: 8px 16px;
      background: #fff;
      border-bottom: solid 1px #e6e6e6;
      z-index: var(--z-content-header);
    }

    .spacing {
      height: 72px;
    }
  }
}
</style>
