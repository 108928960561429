import { RouteLocation } from 'vue-router';
import axios from 'axios';
import {
  AdpDocument,
  Article,
  DocType,
  FollowListEventName,
  FollowListTrackCommonProps,
  IntegrationTrackingsRequest,
  KeywordFeed,
  NotificationType,
  SimilarUserDocumentsShowMoreInfo,
  SimilarUserDocumentsShowMoreName,
  TeamMarkSortType,
  TrackingPageType,
  UserDocumentDemoEventName,
  UserDocumentDemoInfo,
  UserDocumentEventName,
  UserDocumentViewInfo,
  UserDocumentViewWithParentInfo,
} from '@/types';

export type PageName =
  | 'home'
  | 'theme'
  | 'activity'
  | 'notification'
  | 'user'
  | 'team'
  | 'search'
  | 'group'
  | 'group_tags'
  | 'article_share'
  | 'article'
  | 'feature_articles'
  | 'onboarding_job_selection'
  | 'onboarding_interest_input'
  | 'onboarding_perspective_input'
  | 'keyword_feed'
  | 'group_activities'
  | 'related_feed'
  | 'personal_news'
  | 'industry_news'
  | 'profile' // プロフィール設定
  | 'news' // ニュース配信設定
  | 'email' // メール配信設定
  | 'password' // パスワード設定
  | 'theme_search' // テーマ検索画面
  | 'research_papers'
  | 'patents'
  | 'personal_news'
  | 'personal_research_papers'
  | 'personal_patents'
  | 'theme_users_admin'
  | 'comment_creation'
  | 'team_marks'
  | 'new_password'
  | 'follow_list'
  | 'survey'; // 要約メニュー

export type Feature =
  | 'themes_pickup'
  | 'team_comments'
  | 'team_marks'
  | 'user_comments'
  | 'my_marks' // ログインしているユーザーのマーク一覧
  | 'my_comments'
  | 'user_marks'
  | 'notification'
  | 'related_feed'
  | 'keyword_feed'
  | 'personal_feed'
  | 'group_comments'
  | 'group_marks'
  | 'group_activities'
  | 'industry_news'
  | 'industry_feature_articles'
  | 'team_feature_articles'
  | 'related_contents'
  | 'research_paper_feed'
  | 'patent_feed'
  | 'personal_research_paper_feed'
  | 'personal_patent_feed'
  | 'my_views'
  | 'search'
  | 'search_summary'
  | 'follow_list'
  | 'user_document_search'
  | 'survey';

export type FeedType = 'all' | 'domestic' | 'foreign';

export type SiteType = 'provider' | 'pdf_direct';

export type PeriodDetail = { from: string; to: string };

export type TrackingBaseData = {
  pageName?: PageName;
  pageUrl?: string;
  pageGroupId?: number;
  feature?: Feature;
  feedType?: FeedType;
  groupId?: number;
};

export type SearchData = {
  query: string;
  lang: string;
  period: string;
  period_detail?: PeriodDetail;
  sort: string;
  result_count: string;
  is_entertainment_sites_filtered: boolean;
  data_sources: string[];
};

export type IndustryData = {
  id: number;
  topic: {
    id: number;
    rank: number;
  };
};

export type ContentsContext =
  | {
      event_name: 'marks_view';
      sort: TeamMarkSortType;
      users: number[];
    }
  | {
      event_name: 'search';
      session_id?: string;
    }
  | {
      event_name: 'user_document_search';
      session_id?: string;
    }
  | {
      event_name: 'related_contents';
      request_id: string;
      search_query: string;
    }
  | ({
      event_name: 'follow_list_view';
    } & FollowListTrackCommonProps)
  | {
      event_name: 'group_activities_view';
      session_id?: string;
    }
  | {
      event_name: 'my_activities_view';
      session_id?: string;
    };

export type TrackingDataProps = TrackingBaseData & {
  groupId?: number;
  adpDocument?:
    | {
        id: number;
        rank?: number;
        lang?: string;
        doc_type?: DocType;
        hit_keywords?: string[];
        synonym_keywords?: string[];
        page_number?: number;
      }
    | Article
    | KeywordFeed;

  themeId?: number;
  rankInWholeFeed?: number;
  searchData?: SearchData;
  industryData?: IndustryData;
  siteType?: SiteType;
  description?: string;
  contentsContext?: ContentsContext;
  enableAllianceMedia?: boolean;
  execution?: string;
};

type TrackingData = {
  page: {
    name?: PageName;
    url?: string;
    group?: {
      id: number;
    };
  };
  group?: {
    id: number;
  };
  article?: {
    id: number;
    type?: DocType;
    hit_keywords?: string[];
    synonym_keywords?: string[];
    alliance_media?: string;
    enable_alliance_media_body?: boolean;
    page_number?: number;
  };
  theme?: {
    id: number;
  };
  feature?: Feature;
  ui_feed_type?: FeedType;
  rank?: number;
  feed?: {
    job_code: string;
    date: string;
    rank: number;
    feed_type: string;
    lang?: string;
    execution?: string;
  };
  search?: SearchData;
  industry?: IndustryData;
  site_type?: SiteType;
  description?: string;
  contents_context?:
    | {
        event_name: 'marks_view';
        sort: 'おすすめ順' | '新着順';
        users: number[];
      }
    | {
        event_name: 'search';
        session_id?: string;
      }
    | {
        request_id: string;
        search_query: string;
      }
    | ({
        event_name: 'follow_list_view';
      } & FollowListTrackCommonProps)
    | {
        event_name: 'group_activities_view';
        session_id?: string;
      }
    | {
        event_name: 'my_activities_view';
        session_id?: string;
      };
};

export const createTrackingData = ({
  groupId,
  adpDocument,
  themeId,
  pageName,
  pageUrl,
  pageGroupId,
  feature,
  feedType,
  rankInWholeFeed,
  searchData: _searchData,
  industryData,
  siteType,
  description,
  contentsContext,
  enableAllianceMedia,
  execution,
}: TrackingDataProps): TrackingData => {
  let data: TrackingData = {
    page: {
      name: pageName,
      url: pageUrl,
    },
    feature: feature,
    ui_feed_type: feedType,
  };
  if (groupId) {
    data = {
      ...data,
      group: {
        id: groupId,
      },
    };
  }
  if (adpDocument) {
    data = {
      ...data,
      article: {
        id: adpDocument.id,
        hit_keywords: adpDocument.hit_keywords,
        synonym_keywords: adpDocument.synonym_keywords,
      },
      rank: rankInWholeFeed ?? adpDocument.rank,
    };
    if (data.article) {
      data.article.type = adpDocument.doc_type ?? 'article';
      if (data.article.type === 'report' && 'page_number' in adpDocument) {
        data.article.page_number = adpDocument.page_number;
      }

      if ('is_alliance_media' in adpDocument && adpDocument.is_alliance_media) {
        data.article.alliance_media = adpDocument.media_name;
        data.article.enable_alliance_media_body = enableAllianceMedia ?? false;
      }
    }
    if (
      (adpDocument as KeywordFeed).feed_date ||
      (adpDocument as Article & { isPastArticle: boolean }).isPastArticle
    ) {
      const keywordFeed = adpDocument as KeywordFeed;
      data = {
        ...data,
        feed: {
          job_code: keywordFeed.feed_job_code,
          date: keywordFeed.feed_date,
          rank: keywordFeed.rank,
          feed_type: adpDocument.lang === 'ja' ? 'domestic' : 'foreign',
          lang: adpDocument.lang,
          execution,
        },
      };
    }
  }
  if (themeId) {
    data = {
      ...data,
      theme: {
        id: themeId,
      },
    };
  }
  if (industryData && description) {
    data = { ...data, industry: industryData, description: description };
  }
  if (siteType) {
    data = { ...data, site_type: siteType };
  }
  if (pageGroupId) {
    data = { ...data, page: { ...data.page, group: { id: pageGroupId } } };
  }
  if (contentsContext) {
    let formattedContentsContext: TrackingData['contents_context'];
    switch (contentsContext.event_name) {
      case 'marks_view':
        formattedContentsContext = {
          event_name: contentsContext.event_name,
          sort: contentsContext.sort === 'latest' ? '新着順' : 'おすすめ順',
          users: contentsContext.users,
        };
        break;
      case 'search':
        formattedContentsContext = {
          event_name: contentsContext.event_name,
          session_id: contentsContext.session_id,
        };
        break;
      case 'related_contents':
        formattedContentsContext = {
          request_id: contentsContext.request_id,
          search_query: contentsContext.search_query,
        };
        break;
      case 'follow_list_view':
        formattedContentsContext = {
          event_name: contentsContext.event_name,
          follow_list: {
            id: contentsContext.follow_list.id,
            type: contentsContext.follow_list.type,
            order: contentsContext.follow_list.order,
            sort: contentsContext.follow_list.sort,
            ...(contentsContext.follow_list.organization_tag
              ? {
                  organization_tag:
                    contentsContext.follow_list.organization_tag,
                }
              : {}),
            ...(contentsContext.follow_list.users
              ? {
                  users: contentsContext.follow_list.users,
                }
              : {}),
          },
        };
        break;
      case 'group_activities_view':
      case 'my_activities_view':
        formattedContentsContext = {
          ...contentsContext,
        };
        break;
    }
    data = {
      ...data,
      contents_context: formattedContentsContext,
    };
  }
  return data;
};

export type TrackingThemeData = {
  pageName: string;
  pageUrl: string;
  keywords: string[];
  from: string;
  rank?: number;
  topics?: string[];
  theme?: { id?: number };
  perspectives?: string[];
};

export type TrackingAutocompleteData = {
  pageName: string | symbol;
  pageUrl: string;
  themeId?: number;
  keywords: string[];
  input_text: string;
  rank: number;
};

export async function trackAccess(): Promise<void> {
  await axios.post<void>('/accesses');
}

export async function trackPageView({
  pageName,
  pageUrl,
  theme,
  group,
  adpDocument,
  feedType,
  fromUrl,
  fromSnackbarLink,
}: {
  pageName: PageName;
  pageUrl: string;
  theme?: { id?: number };
  group?: { id?: number };
  adpDocument?: {
    id?: number;
    doc_type: DocType;
    alliance_media?: string;
    enable_alliance_media_body?: boolean;
  };
  feedType?: FeedType;
  fromUrl?: string | null;
  fromSnackbarLink?: string;
}): Promise<void> {
  await axios.post<void>('/page_views', {
    page: {
      name: pageName,
      url: pageUrl,
    },
    theme: theme,
    group: group,
    article: adpDocument,
    feed_type: feedType,
    from_url: fromUrl,
    from_snackbar_link: fromSnackbarLink,
  });
}

export async function sendView({
  adpDocument,
  themeId,
  groupId,
  trackingBaseData,
  rankInWholeFeed,
  searchData,
  industryData,
  siteType,
  description,
  contentsContext,
  enableAllianceMedia,
  execution,
}: {
  adpDocument?: AdpDocument;
  themeId?: number;
  groupId?: number;
  rankInWholeFeed?: number;
  trackingBaseData?: TrackingBaseData;
  searchData?: SearchData;
  industryData?: IndustryData;
  siteType?: SiteType;
  description?: string;
  contentsContext?: ContentsContext;
  enableAllianceMedia?: boolean;
  execution?: string;
}): Promise<void> {
  await axios.post<void>('/views', {
    article_id: adpDocument?.id,
    doc_type: adpDocument?.doc_type ?? 'article',
    theme_id: themeId ?? undefined,
    tracking_data: createTrackingData({
      adpDocument,
      themeId,
      groupId,
      rankInWholeFeed,
      ...trackingBaseData,
      searchData,
      industryData,
      siteType,
      description,
      contentsContext,
      enableAllianceMedia,
      execution,
    }),
  });
}

export async function trackIntegration(
  type: 'comment' | 'article',
  route: RouteLocation,
  additional_data: any = {}, // eslint-disable-line @typescript-eslint/no-explicit-any
): Promise<void> {
  const queryParams = route.query;
  const from = queryParams.from as string;
  const eventSourceName = queryParams.eventSourceName as string;
  const userId = queryParams.userId ? Number(queryParams.userId) : null;
  const themeId = queryParams.themeId ? Number(queryParams.themeId) : null;
  const groupId = queryParams.groupId ? Number(queryParams.groupId) : null;
  const feature = queryParams.feature || undefined;
  if (from && eventSourceName) {
    const json: IntegrationTrackingsRequest = {
      ...additional_data,
      from: from,
      event_source: {
        name: eventSourceName,
      },
    };

    let eventType:
      | 'comment_view'
      | 'group_article_mark_dialog'
      | 'article_mark_dialog'
      | 'comment_dialog'
      | 'article_view'
      | undefined;
    if (eventSourceName === 'comment' && userId !== null) {
      eventType = 'comment_view';
      json.event_source.comment = { user_id: userId };
    } else if (eventSourceName === 'mark' && userId !== null) {
      eventType = 'article_view';
      json.event_source.mark = { user_id: userId };
    } else if (
      eventSourceName === 'group_mark' ||
      eventSourceName === 'last_week_highlight'
    ) {
      eventType = 'article_view';
    } else if (
      queryParams.modal &&
      (eventSourceName === 'latest_news' ||
        eventSourceName === 'weekly_highlight' ||
        eventSourceName === 'overlooked_news')
    ) {
      if (type === 'article' && queryParams.modal === 'group_mark')
        eventType = 'group_article_mark_dialog';
      else if (type === 'article' && queryParams.modal === 'mark')
        eventType = 'article_mark_dialog';
      else if (type === 'article' && queryParams.modal === 'comment')
        eventType = 'comment_dialog';

      if (themeId) json.theme = { id: themeId };
      if (groupId) json.group = { id: groupId };
      if (typeof feature === 'string') json.event_source.feature = feature;
    } else if (
      type === 'article' &&
      !queryParams.modal &&
      eventSourceName &&
      from === 'mail'
    ) {
      if (themeId) json.theme = { id: themeId };
      if (groupId) json.group = { id: groupId };
      eventType = 'article_view';
      json.from = 'mail';
      if (queryParams.feature) {
        json.event_source.feature = String(queryParams.feature);
      }
    }
    if (!eventType) return;
    await axios.post<void>(
      `/integration_trackings?event_type=${eventType}`,
      json,
    );
  }
}

export async function trackEvent(
  name: string,
  trackingBaseData: TrackingBaseData,
  articleId?: number,
  groupId?: number,
  additionalData?: any, // eslint-disable-line @typescript-eslint/no-explicit-any
): Promise<void> {
  const trackingData = {
    ...createTrackingData({
      ...trackingBaseData,
      adpDocument: articleId ? { id: articleId } : undefined,
      groupId,
    }),
    ...additionalData,
  };
  await axios.post<void>(`/tracking_events`, {
    name,
    ...trackingData,
  });
}

export async function trackThemeEvent(
  name: 'to_create_theme' | 'add_keywords',
  {
    pageName,
    pageUrl,
    keywords,
    from,
    rank,
    topics,
    theme,
    perspectives,
  }: TrackingThemeData,
): Promise<void> {
  await axios.post<void>('/tracking_theme_events', {
    name,
    page: {
      name: pageName,
      url: pageUrl,
    },
    theme: {
      id: theme?.id,
    },
    keywords,
    from,
    rank,
    topics,
    perspectives,
  });
}

export async function trackAutocompleteEvent(
  data: TrackingAutocompleteData,
): Promise<void> {
  await axios.post<void>('/tracking_theme_events', {
    name: 'add_keywords',
    from: 'auto_completion',
    page: {
      name: data.pageName,
      url: data.pageUrl,
    },
    theme: {
      id: data.themeId ?? null,
    },
    keywords: data.keywords,
    input_text: data.input_text,
    rank: data.rank,
  });
}

export async function trackingFollowListEvent(
  name: FollowListEventName,
  trackingData: FollowListTrackCommonProps & TrackingPageType<'follow_list'>,
): Promise<void> {
  await axios.post<void>(`/tracking_events`, {
    name,
    ...trackingData,
  });
}

export async function trackingUserDocumentEvent(
  name: UserDocumentEventName,
  trackingData: UserDocumentViewInfo | UserDocumentViewWithParentInfo,
): Promise<void> {
  await axios.post<void>(`/tracking_events`, {
    name,
    ...trackingData,
  });
}

export async function trackingSimilarUserDocumentsShowMoreEvent(
  name: SimilarUserDocumentsShowMoreName,
  trackingData: SimilarUserDocumentsShowMoreInfo,
): Promise<void> {
  await axios.post<void>(`/tracking_events`, {
    name,
    ...trackingData,
  });
}

export async function trackingUserDocumentsDemoEvent(
  name: UserDocumentDemoEventName,
  trackingData?: UserDocumentDemoInfo,
) {
  await axios.post<void>(`/tracking_events`, {
    name,
    ...trackingData,
  });
}

/**
 * web pushの計測はpage nameが不要のため別の関数として定義している
 * service worker内ではXHRが使えないためXHRベースのaxiosでなくfetchを使っている
 */
export async function webPushTrackEvent(
  name: string,
  additionalData: {
    user_agent: string;
    notification: { id: number; type: NotificationType };
  },
): Promise<void> {
  const response = await fetch('/api/v1/tracking_events', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name,
      ...additionalData,
    }),
  });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
}
