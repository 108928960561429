// フロントから計測を行う際のhelper
import axios from 'axios';
import {
  AdpDocument,
  Lang,
  OnlyTrackingParam,
  ReportSearchRequestParam,
  SearchRequestParam,
  UserDocument,
  UserDocumentSearchRequestParam,
} from '@/types';
import {
  SITE_CATEGORY_LABEL_MAP,
  SiteCategoryItemsLabels,
} from './siteCategories';

/* 検索の計測用の型 */
export type SearchEventName = 'search' | 'user_document_search';
export type SearchTrackingData<T> = {
  query: string | null;
  lang?: Lang | 'all';
  period: string;
  period_detail: {
    from: string;
    to: string;
  } | null;
  sort: string;
  result_count: number;
  is_entertainment_sites_filtered?: boolean;
  data_sources: string[];
  request?: {
    offset: number;
    limit: number;
  };
  contents: T[];
  session_id: string; // search_tracking_session_id
  query_conversion?: { expansion: string[] };
  site_categories?: string[];
  contents_context?: {
    request_id: string;
    search_query: string;
  };
  feature?: string;
  rank?: number;
};

export type UserDocumentSearchTrackingData<T> = Omit<
  SearchTrackingData<T>,
  'data_sources'
>;

export type UserDocumentContent = {
  user_document: {
    id: string;
  };
  rank: number;
};

export type ArticleContent = {
  article: {
    id: number;
    type: string;
    hit_keywords: string[];
    page_number: number | null;
  };
  rank: number;
};

export const trackingSearchEvent = async (
  name: SearchEventName,
  trackingData:
    | SearchTrackingData<ArticleContent>
    | UserDocumentSearchTrackingData<UserDocumentContent>,
): Promise<void> => {
  try {
    await axios.post<void>(`/tracking_events`, {
      name,
      ...trackingData,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Failed to send search tracking event.', e);
  }
};

/**
 * - 検索 （社内情報以外） の計測に必要なデータを作成する
 * - バックエンドの`def get_event_data_for_search_api`でやっているロジックのフロント版
 */
export const getEventDataForSearchAPI = (
  params:
    | (SearchRequestParam & OnlyTrackingParam)
    | (ReportSearchRequestParam & OnlyTrackingParam),
  articles: AdpDocument[],
  count: number,
  searchTrackingSessionId: string, // この関数が呼ばれる時点でsession_idは必ず存在する (バックエンドではpagingではない初回リクエストでsession_idが生成される)
): SearchTrackingData<ArticleContent> => {
  const period_detail =
    params.period === 'custom'
      ? {
          from: params.from_date ?? '',
          to: params.to_date ?? '',
        }
      : null;

  let site_categories: SiteCategoryItemsLabels[] | undefined;
  if ('doc_types' in params && 'site_categories' in params)
    site_categories = params.doc_types?.includes('article')
      ? Object.entries(SITE_CATEGORY_LABEL_MAP)
          .filter(
            ([key]) =>
              params.site_categories?.includes(key) ||
              (key === 'Alliance Media' && params.include_alliance_media),
          )
          .map(([, value]) => value)
      : undefined;

  let request: SearchTrackingData<ArticleContent>['request'];
  let contents: SearchTrackingData<ArticleContent>['contents'] = [];

  if (params.page !== undefined && params.limit !== undefined) {
    const offset = (params.page - 1) * params.limit;
    request = { offset, limit: params.limit };

    contents = articles.map((article, i) => ({
      article: {
        id: article.id,
        type: article.doc_type,
        hit_keywords: article.hit_keywords ?? [],
        page_number:
          article.doc_type === 'report' ? article?.page_number ?? null : null,
      },
      rank: i + offset + 1,
    }));
  }

  let contents_context: SearchTrackingData<ArticleContent>['contents_context'];
  if (params.request_id && params.search_query)
    contents_context = {
      request_id: params.request_id,
      search_query: params.search_query,
    };

  let data: SearchTrackingData<ArticleContent> = {
    query: params.query ?? null,
    lang: params.lang ?? 'all',
    period: params.period ?? 'all',
    period_detail,
    sort: params.sort ?? 'recommended',
    result_count: count,
    is_entertainment_sites_filtered:
      'entertainment_filter' in params ? params.entertainment_filter : false,
    data_sources: params.doc_types ?? ['article'],
    site_categories,
    contents_context,
    feature: params.feature,
    request,
    rank: params.rank,
    contents,
    session_id: searchTrackingSessionId,
    query_conversion: { expansion: params.cached_expanded_queries ?? [] },
  };

  return data;
};

/**
 * - 社内情報検索の計測に必要なデータを作成する
 * - バックエンドの`def get_event_data_for_team_search_api`でやっているロジックのフロント版
 *
 * ※ 社内情報は機微な情報をログに登録しないようにする (search APIで該当する項目: query, query_conversion, contents: [{article: {hit_keywords, page_number}])
 */
export const getEventDataForTeamSearchAPI = (
  params: UserDocumentSearchRequestParam & OnlyTrackingParam,
  articles: UserDocument[],
  count: number,
  searchTrackingSessionId: string, // この関数が呼ばれる時点でsession_idは必ず存在する (バックエンドではpagingではない初回リクエストでsession_idが生成される)
): UserDocumentSearchTrackingData<UserDocumentContent> => {
  const period_detail =
    params.period === 'custom'
      ? { from: params.from_date ?? '', to: params.to_date ?? '' }
      : null;

  let request: SearchTrackingData<UserDocumentContent>['request'];
  let contents: SearchTrackingData<UserDocumentContent>['contents'] = [];

  if (params.page !== undefined && params.limit !== undefined) {
    const offset = (params.page - 1) * params.limit;
    request = { offset, limit: params.limit };

    contents = articles.map((article, i) => ({
      user_document: {
        id: article.id,
        similar_user_documents: article.similar_documents.map(
          (similar_document, j) => ({
            user_document: {
              id: similar_document.id,
            },
            rank: j + 1,
          }),
        ),
      },
      rank: i + offset + 1,
    }));
  }

  let data: UserDocumentSearchTrackingData<UserDocumentContent> = {
    query: null, // NOTE: queryはnullしか入れないが、項目自体は登録する
    period: params.period ?? 'all',
    period_detail,
    sort: params.sort ?? 'recommended',
    request,
    contents,
    session_id: searchTrackingSessionId,
    result_count: count,
    feature: params.feature,
    rank: params.rank,
  };

  return data;
};
