<script setup lang="ts">
import { computed, onMounted, ref, Ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import api from '@/api';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import GroupHeader from '@/components/group/group-header.vue';
import GroupTagListTable from '@/components/group/group-tag-list-table.vue';
import AddGroupTagModal from '@/components/group/tags/add-group-tag-modal.vue';
import DeleteGroupTagModal from '@/components/group/tags/delete-group-tag-modal.vue';
import EditGroupTagModal from '@/components/group/tags/edit-group-tag-modal.vue';
import Content from '@/components/layouts/content.vue';
import {
  GroupTag,
  GroupTagSortAttribute,
  GroupTagSortOrder,
  GroupTagSortTypes,
  GroupTagWithCount,
} from '@/types';
import {
  STATES,
  useGroups,
  useGroupTagTotalCount,
  useUserInfo,
} from '@/utils/swr';
import { useEmitter } from '@/utils/vue';

const isOpenCreateGroupTagModal = ref(false);
const isOpenEditGroupTagModal = ref(false);
const isOpenDeleteGroupTagModal = ref(false);

const modifiableGroupTag: Ref<{ id: number; name: string } | undefined> =
  ref(undefined);

const { createSnackbar } = useSnackbar();
const emitter = useEmitter();
const router = useRouter();
const { data: groups, state: groupLoadingState } = useGroups();

const route = useRoute();
const { data: myInfo } = useUserInfo();
const groupId = computed(() => {
  if (
    route.params.groupId instanceof Array &&
    route.params.groupId[0] !== undefined
  ) {
    return Number(route.params.groupId[0]);
  }
  if (route.params.groupId !== undefined) {
    return Number(route.params.groupId);
  }
  return undefined;
});

const sortAttribute = ref<GroupTagSortAttribute | undefined>(undefined);
const sortOrder = ref<GroupTagSortOrder | undefined>(undefined);

const sortType = computed<GroupTagSortTypes | undefined>(() => {
  if (sortAttribute.value === undefined || sortOrder.value === undefined) {
    return undefined;
  }
  return `${sortAttribute.value}_${sortOrder.value}`;
});

const {
  data: groupTagTotalCount,
  state: groupTagTotalCountLoadingState,
  mutate: groupTagTotalCountMutate,
} = useGroupTagTotalCount(groupId);

const openCreateTagModal = () => {
  isOpenCreateGroupTagModal.value = true;
};
const closeCreateTagModal = () => {
  isOpenCreateGroupTagModal.value = false;
};

const openEditTagModal = ({
  groupTagId,
  tagName,
}: {
  groupTagId: number;
  tagName: string;
}) => {
  modifiableGroupTag.value = { id: groupTagId, name: tagName };
  isOpenEditGroupTagModal.value = true;
};
const closeEditTagModal = () => {
  isOpenEditGroupTagModal.value = false;
};

const openDeleteTagModal = ({
  groupTagId,
  tagName,
}: {
  groupTagId: number;
  tagName: string;
}) => {
  modifiableGroupTag.value = { id: groupTagId, name: tagName };
  isOpenDeleteGroupTagModal.value = true;
};
const closeDeleteTagModal = () => {
  isOpenDeleteGroupTagModal.value = false;
};

const submitCreateTagModal = ({ groupTag }: { groupTag: GroupTag }) => {
  emitter.emit('pagination-item-create', {
    ...groupTag,
    total_count: 0,
  });
  closeCreateTagModal();
  groupTagTotalCountMutate();
};
const submitDeleteTagModal = ({ groupTagId }: { groupTagId: number }) => {
  emitter.emit('pagination-item-delete', (items: GroupTagWithCount[]) => {
    const index = items.findIndex(c => c.id === groupTagId);
    if (index >= 0) {
      items.splice(index, 1);
    }
  });
  groupTagTotalCountMutate();
  closeDeleteTagModal();
};
const submitEditTagModal = ({
  groupTagId,
  tagName,
}: {
  groupTagId: number;
  tagName: string;
}) => {
  emitter.emit('pagination-items-update', {
    filterFunc: (_: GroupTagWithCount) => true,
    updateFunc: (groupTags: GroupTagWithCount[]) => {
      groupTags.forEach(tag => {
        if (tag.id === groupTagId) {
          tag.name = tagName;
        }
      });
    },
  });
  closeEditTagModal();
};

const group = computed(() =>
  groups.value?.groups.find(g => g.id === groupId.value),
);

const sortItems = (attribute: GroupTagSortAttribute) => {
  if (sortAttribute.value === attribute) {
    sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc';
  } else {
    sortAttribute.value = attribute;
    sortOrder.value = 'asc';
  }
};

watch([() => groupId.value, () => group.value], () => {
  validateGroup();
});
onMounted(() => {
  validateGroup();
  api.trackPageView({
    pageName: 'group_tags',
    pageUrl: route.path,
  });
});

const validateGroup = () => {
  // グループから他のページに移動したらバリデーションが必要ない
  if (groupId.value === undefined) return false;

  if (groupLoadingState.value === STATES.SUCCESS && group.value === undefined) {
    createSnackbar({
      message: '指定されたグループは存在しません',
      type: 'error',
    });
    router.replace({ name: 'anewsHome' });
    return false;
  }
  return true;
};
</script>

<template>
  <div class="group-tag-list" v-if="group && myInfo">
    <GroupHeader :group="group" :user-info="myInfo" />
    <Content>
      <div class="tag-list-content-header">
        <div class="title">
          <span class="c-title c-title--m">グループ</span>
          <span
            v-if="
              groupTagTotalCountLoadingState === STATES.PENDING ||
              groupTagTotalCountLoadingState === STATES.VALIDATING ||
              groupTagTotalCount?.group_tag_count === undefined
            "
            class="count c-text c-text--s"
            >・- 件</span
          >
          <span v-else class="count c-text c-text--s"
            >・{{ groupTagTotalCount.group_tag_count }}件</span
          >
        </div>
        <div>
          <button
            class="group-tag-add-button o-create-button c-btn c-btn--auto c-btn--AnewsPrimary"
            @click="openCreateTagModal"
          >
            作成
          </button>
        </div>
      </div>
      <GroupTagListTable
        v-if="groupId"
        :group-id="groupId"
        :sort-type="sortType"
        @open-edit-tag-modal="openEditTagModal"
        @open-delete-tag-modal="openDeleteTagModal"
        @sort-items="sortItems"
      />
    </Content>
    <AddGroupTagModal
      v-if="groupId"
      :is-open="isOpenCreateGroupTagModal"
      :group-id="groupId"
      @close="closeCreateTagModal"
      @submit="submitCreateTagModal"
    />
    <EditGroupTagModal
      v-if="modifiableGroupTag"
      :is-open="isOpenEditGroupTagModal"
      :group-tag-id="modifiableGroupTag.id"
      :group-tag-name="modifiableGroupTag.name"
      @close="closeEditTagModal"
      @submit="submitEditTagModal"
    />
    <DeleteGroupTagModal
      v-if="modifiableGroupTag"
      :is-open="isOpenDeleteGroupTagModal"
      :group-tag-id="modifiableGroupTag.id"
      :group-tag-name="modifiableGroupTag.name"
      @close="closeDeleteTagModal"
      @submit="submitDeleteTagModal"
    />
  </div>
</template>

<style lang="scss" scoped>
.group-tag-list {
  width: 100%;
  margin: -24px 0 0 0;
}

.c-btn {
  height: fit-content;
  padding: 6px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.count {
  color: $color-gray600;
}

.tag-list-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 21px;
}

.group-tag-add-button {
  border: none;
}

.min-cell {
  width: 80px;
}

.empty-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65px;
  color: $color-gray600;
}
</style>
