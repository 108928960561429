<script setup lang="ts">
import { reactive, ref } from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api';
import { MAX_ARTICLE_MEMO_LENGTH } from '@/constants';
import AdpDocumentCard from '@/components/common/adp-document-card.vue';
import InputTag from '@/components/common/atoms/input-tag.vue';
import TextCounter from '@/components/common/molecules/text-counter.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import GroupSelect from '@/components/modals/group-select.vue';
import { Group, GroupMark, Tag } from '@/types';
import { userSession } from '@/utils/userSession';
import { useEmitter, useStore } from '@/utils/vue';

const store = useStore();
const route = useRoute();
const emitter = useEmitter();
const { createSnackbar } = useSnackbar();
// HACK: モーダルを開いている時点でmodal.theme, modal.pageNameに値は入っているため、undefinedになる場合を考慮しない。要リファクタリング
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const article = store.state.modal.article!;
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const theme = store.state.modal.theme!;
const group = store.state.modal.group;
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const pageName = store.state.modal.pageName!;
const feature = store.state.modal.feature;
const feedType = store.state.modal.feedType;
const markSource = store.state.modal.markSource;
const rankInWholeFeed = store.state.modal.rankInWholeFeed;
const searchData = store.state.modal.searchData;
const industryData = store.state.modal.industryData;
const description = store.state.modal.description;
const contentsContext = store.state.modal.contentsContext;
const execution = store.state.modal.execution;

const tagList: { list: Tag[] } = reactive({ list: [] });
const selectedTags = ref<string[]>([]);
const selectedGroup = ref<Group | undefined>(undefined);
const editingMemo = ref('');
const isMarkedGroup = ref(false);

const initialGroupId = group?.id ?? userSession.getLastUsedGroupId();

const setSelectedGroup = async (group: Group) => {
  selectedGroup.value = group;
  const groupNames = article.group_marks.map(g => g.group_name);
  isMarkedGroup.value = groupNames.includes(group.name);
  tagList.list = await api.fetchGroupTags(group.id);
};

const hideDialog = () => {
  store.commit('modal/hideModal');
};

const updateArticle = (resGroupMark: GroupMark) => {
  const updateArticle = article;
  const newGroupMark: GroupMark = {
    ...resGroupMark,
    group_name: selectedGroup.value?.name ?? '',
  };
  updateArticle.group_marks.push(newGroupMark);
  emitter.emit('article-updated', updateArticle);
};
const submit = async () => {
  if (!selectedGroup.value) return;
  const groupId = selectedGroup.value.id;
  if (initialGroupId !== groupId) {
    userSession.setLastUsedGroupId(groupId);
  }

  let pageGroupIdParam = route.params.groupId;
  if (pageGroupIdParam instanceof Array) {
    pageGroupIdParam = pageGroupIdParam[0];
  }

  try {
    const resGroupMarkPromise = api.postGroupMark(
      groupId,
      article,
      theme?.id,
      markSource,
      {
        pageName: pageName,
        pageUrl: route.fullPath,
        pageGroupId: pageGroupIdParam ? parseInt(pageGroupIdParam) : undefined,
        feature: feature,
        feedType: feedType,
      },
      rankInWholeFeed,
      editingMemo.value,
      selectedTags.value,
      searchData,
      industryData,
      description,
      contentsContext,
      execution,
    );
    const promises: Promise<void>[] = [];
    if (selectedTags.value.length > 0) {
      promises.push(
        api.updateGroupArticleTags(
          groupId,
          article.id,
          article.doc_type,
          selectedTags.value,
          article.lang,
        ),
      );
    }
    if (editingMemo.value !== '') {
      promises.push(
        api.updateGroupArticleMemo(
          groupId,
          article.id,
          article.doc_type,
          editingMemo.value,
          article.lang,
        ),
      );
    }
    promises.push(
      api.trackEvent(
        'set_article_option',
        {
          pageName: pageName,
          pageUrl: route.fullPath,
          feature: feature,
        },
        article.id,
        groupId,
        {
          tags: selectedTags.value,
          memo: editingMemo.value,
        },
      ),
    );
    const [resGroupMark] = await Promise.all([
      resGroupMarkPromise,
      ...promises,
    ]);
    updateArticle(resGroupMark);
    createSnackbar({
      type: 'link',
      message: selectedGroup.value.name,
      params: {
        link: `/groups/${groupId}/marks`,
        suffixText: 'にグループマークしました',
      },
    });
  } catch {
    createSnackbar({
      message: 'グループマークできませんでした',
      type: 'error',
    });
  }
  hideDialog();
};
</script>

<template>
  <div class="o-dialog-group-mark">
    <sm-dialog class="auto-height auto-width screen-center" @close="hideDialog">
      <template #header>
        <div class="o-header c-text c-text--m">グループマーク</div>
      </template>
      <template #body>
        <div class="o-body c-text c-text--m">
          <div class="o-group-select">
            <GroupSelect
              @selected="setSelectedGroup"
              options-max-height="160px"
              :is-error="isMarkedGroup"
              :initial-group-id="initialGroupId"
            ></GroupSelect>
            <div class="o-group-select__text c-text c-text--m">
              にグループマーク
            </div>
          </div>
          <div class="o-group-select__error" v-if="isMarkedGroup">
            すでにこの記事は指定したグループでマークされています
          </div>
          <div class="spacing-12"></div>
          <AdpDocumentCard
            :adp-document="article"
            :theme-id="theme ? theme.id : undefined"
            :show-comment="false"
            :show-user-action="false"
            :page-name="pageName"
            :feature="feature"
          ></AdpDocumentCard>
          <div class="spacing-08"></div>
          <InputTag
            :tag-list="tagList.list"
            v-model="selectedTags"
            placeholder="グループタグを入力後、改行でグループタグを追加"
            :is-editing="true"
            :is-group="true"
          ></InputTag>
          <div class="c-formBlock">
            <div class="c-formBlock__label">グループメモ</div>
            <textarea
              class="o-article-memo c-textArea"
              v-model.trim="editingMemo"
              :maxlength="MAX_ARTICLE_MEMO_LENGTH"
              placeholder="グループメモを入力"
              @keydown.ctrl.enter="submit"
              @keydown.meta.enter="submit"
            ></textarea>
            <TextCounter
              :text="editingMemo"
              :max-length="MAX_ARTICLE_MEMO_LENGTH"
            />
          </div>
        </div>
      </template>
      <template #footer>
        <div class="o-footer c-text c-text--m">
          <button
            class="o-create-button c-btn c-btn--auto c-btn--AnewsPrimary"
            :class="{ disabled: isMarkedGroup }"
            :disabled="isMarkedGroup"
            @click="submit"
          >
            グループマーク
          </button>
        </div>
      </template>
    </sm-dialog>
  </div>
</template>
<style lang="scss" scoped>
.o-dialog-group-mark {
  .o-group-select {
    display: flex;
    align-items: center;
    .o-group-select__text {
      margin-left: 8px;
    }
  }
  .o-group-select__error {
    color: #c6002f;
  }
  .o-article {
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    .o-article-info {
      width: 484px;
      padding: 16px;
      box-sizing: border-box;
      .o-article-info__title {
        font-family: Noto Sans CJK JP;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
      }
      .o-article-info__media {
        color: #b3b3b3;
      }
    }
    .o-article-image {
      width: 100px;
      height: 100px;
      object-fit: cover;
      object-position: 50% 25%;
      border-left: 1px solid #e6e6e6;
      border-radius: 0 3px 3px 0;
    }
  }
  .o-article-memo {
    height: 140px;
    resize: none;
    outline: none;
  }
}
textarea::placeholder {
  color: #b3b3b3;
}

.o-body {
  width: 616px;
}
.o-footer {
  margin-top: -16px;
}
</style>
