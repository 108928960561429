<script setup lang="ts">
import {
  computed,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  Ref,
  ref,
  toRefs,
  UnwrapRef,
  watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import api from '@/api';
import { PageName } from '@/api/tracking';
import { DgrIcon, DgrLoading } from '@stockmarkteam/donguri-ui';
import { AxiosError } from 'axios';
import AdpDocumentCard from '@/components/common/adp-document-card.vue';
import AdpDocumentInfoDefault from '@/components/common/adp-document/adp-document-info-default.vue';
import AdpDocumentInfo from '@/components/common/adp-document/adp-document-info.vue';
import SearchBar from '@/components/common/molecules/search-bar.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import SwrScrollingPagination from '@/components/common/swr-scrolling-pagination.vue';
import Content from '@/components/layouts/content.vue';
import Header from '@/components/layouts/header.vue';
import {
  AdpDocument,
  ArticleUpdateOption,
  Tag,
  UserSettings as UserSettingsType,
} from '@/types';
import { isEmptyObject, Pagination } from '@/utils';
import {
  isMypageRestricted as isMypageRestrictedUtil,
  mypageAccessScope as mypageAccessScopeUtil,
} from '@/utils/mypageAccessScope';
import { ContentPosition, syncContentScrollWithViewport } from '@/utils/scroll';
import { selectArticle } from '@/utils/selectArticle';
import {
  STATES,
  useUserActionCount,
  useUserMarkedArticleCounts,
  useUserMarkedArticles,
  useUserOrganizationTags,
} from '@/utils/swr';
import { useEmitter, useStore } from '@/utils/vue';

const LIMIT = 10;
const CONTENT_HEADER_HEIGHT = 160;
const PAGE_NAME: PageName = 'user';

const store = useStore();
const emitter = useEmitter();
const route = useRoute();
const { createSnackbar } = useSnackbar();

interface Props {
  userId?: string | number;
}

const props = defineProps<Props>();
const { userId } = toRefs(props);
const uId = computed(() => (userId?.value ? Number(userId.value) : undefined));

const { data: userOrganizationTags } = useUserOrganizationTags(uId);

const { mutate: mutateMarkCount } = useUserActionCount(uId, 'mark');

const pagination = ref<Pagination | undefined>(undefined);
const userInfo = computed(() => store.state.userInfo.userInfo);
const targetUserInfo = computed(() => store.state.userActions.targetUserInfo);
const isSharing = computed(() => store.state.modal.isSharing);
const selectedArticles = ref<AdpDocument[]>([]);
const articleTags = ref<Tag[]>([]);
const targetUserSettings = ref<UserSettingsType | null>(null);

const abortController = new AbortController();
const rightContent = ref<HTMLElement>();
let resizeObserver: ResizeObserver | undefined = undefined;
onMounted(async () => {
  await setUp();
  const signal = abortController.signal;
  window.addEventListener('scroll', handleScroll, { signal });
  window.addEventListener('resize', handleResize, { signal });
  handleScroll();
  resizeObserver?.disconnect();
  resizeObserver = new ResizeObserver(() => handleResize());
  if (rightContent.value) {
    resizeObserver.observe(rightContent.value);
  }
  emitter.on('article-updated', handleMarkCountUpdate);
});

onUnmounted(() => {
  emitter.off('article-updated', handleMarkCountUpdate);
});

const handleMarkCountUpdate = () => {
  mutateMarkCount();
};

const setUp = async () => {
  if (uId.value === undefined) {
    return;
  }
  await store.dispatch('userActions/fetchTargetUserInfo', uId.value);
  targetUserSettings.value = await api.getUserSettings(uId.value);
  fetchArticlesWithPaginateReset();
  articleTags.value = await api.fetchUserArticleTags(uId.value);
  await trackPageView();
};

const updateSearchKeyword = async () => {
  searchQueryForApi.value = searchQuery.value;
};

watch(isSharing, newVal => {
  if (newVal) {
    store.commit('modal/setIsSharing', false);
  }
});

onBeforeUnmount(() => {
  pagination.value?.removeEvent();
  abortController.abort();
  resizeObserver?.disconnect();
});

const isMypageRestricted = computed(() => {
  // マイページ公開制限
  if (!targetUserSettings.value) {
    return false;
  }
  if (isEmptyObject(targetUserInfo.value)) {
    return false;
  }

  return isMypageRestrictedUtil(
    userInfo.value,
    targetUserInfo.value,
    targetUserSettings.value,
  );
});

const mypageAccessScope = computed(() => {
  // マイページ公開範囲
  if (!targetUserSettings.value) {
    return '';
  }

  return mypageAccessScopeUtil(targetUserSettings.value);
});

const selectedArticle = computed<AdpDocument | undefined>(() => {
  if (selectedArticles.value.length === 1) {
    return selectedArticles.value[0];
  }
  return undefined;
});

const articleSelected = (id: number): boolean | undefined => {
  return selectedArticles.value.map(a => a.id).includes(id);
};

const articleHovered = (id: number): boolean | undefined => {
  return !selectedArticles.value.map(a => a.id).includes(id);
};

const clickArticle = (e: MouseEvent, article: AdpDocument): void => {
  selectedArticles.value = selectArticle(e, article, selectedArticles.value);
};

const updateArticle = async (
  article: AdpDocument,
  updateOption: ArticleUpdateOption,
): Promise<void> => {
  try {
    if (updateOption.shouldUpdateMemo) {
      if (article.memo) {
        await api.updateUserArticleMemo(
          article.id,
          article.doc_type,
          article.memo,
          article.lang,
        );
      } else {
        await api
          .deleteUserArticleMemo(article.id, article.doc_type, article.lang)
          .catch((err: AxiosError) => {
            if (err.response?.status !== 404) {
              throw err;
            }
          });
      }
    }
    if (updateOption.shouldUpdateTags) {
      await api.updateUserArticleTags(
        article.id,
        article.doc_type,
        article.tags ?? [],
        article.lang,
      );
    }
    await api.trackEvent(
      'set_article_option',
      {
        pageName: PAGE_NAME,
        pageUrl: route.fullPath,
        feature: 'user_marks',
      },
      article.id,
      undefined,
      {
        tags: article.tags,
        memo: article.memo,
      },
    );
    selectedArticles.value = selectedArticles.value.map(selectedArticle =>
      selectedArticle.id === article.id ? article : selectedArticle,
    );
    emitter.emit('article-updated', article);
    updateArticleForArticleList(article);
    createSnackbar({
      message: '記事情報を更新しました',
      type: 'success',
    });
  } catch {
    createSnackbar({
      message: '記事情報を更新できませんでした',
      type: 'error',
    });
  }
};

watch(
  () => uId?.value,
  () => setUp(),
);

const themeId = (article: AdpDocument) => {
  if (article.marks.length === 0) return 0;
  return article.marks[0].theme_id ?? undefined;
};

const trackPageView = async () => {
  const previousRoute = store.state.route.previousRoute;
  const hasPreviousRoute = !!previousRoute?.name;
  const isSnackbar =
    hasPreviousRoute &&
    String(previousRoute.params.userId) !== String(uId.value);
  await api.trackPageView({
    pageName: PAGE_NAME,
    pageUrl: route.fullPath,
    fromUrl: previousRoute?.path ?? null,
    fromSnackbarLink: isSnackbar ? 'article_mark' : undefined,
  });
};

const prevWindowScrollY = ref(0);
const contentPosition = ref<ContentPosition>('top');
const handleResize = (event?: Event): void => {
  handleScroll(event, true);
};

const rightColumn = ref<HTMLElement>();
const handleScroll = (_event?: Event, reset = false): void => {
  if (!rightContent.value || !rightColumn.value) return;

  const data = syncContentScrollWithViewport(
    rightContent.value,
    rightColumn.value,
    prevWindowScrollY.value,
    contentPosition.value,
    CONTENT_HEADER_HEIGHT,
    0,
    reset,
  );

  if (data) {
    prevWindowScrollY.value = data.windowScrollY;
    contentPosition.value = data.contentPosition;
  }
};

// 検索窓
const searchQuery = ref<string>('');
const handleChangeQuery = (query: string) => {
  searchQuery.value = query;
};
const searchQueryForApi = ref<string>();

const fetchArticlesWithPaginateReset = () => {
  const filterMarkedArticles = async () => {
    if (uId.value === undefined) return;
    await updateSearchKeyword();
  };

  if (pagination.value) {
    pagination.value.removeEvent();
  }
  pagination.value = new Pagination(filterMarkedArticles, LIMIT);
};

const { data: userMarkedArticleCounts, state: userMarkedArticleCountsState } =
  useUserMarkedArticleCounts(uId, searchQueryForApi);

const isFetchingMarkedArticleCounts = computed(
  () => userMarkedArticleCountsState.value !== STATES.SUCCESS,
);

const totalMarkedArticleCounts = computed(() => {
  if (isFetchingMarkedArticleCounts.value) return '-';
  return (
    (userMarkedArticleCounts.value?.counts.article || 0) +
    (userMarkedArticleCounts.value?.counts.patent || 0) +
    (userMarkedArticleCounts.value?.counts.research_paper || 0)
  ).toString();
});

const getMarkedArticles = (pageRef: Ref<number>, pageLimit: number) => {
  return useUserMarkedArticles(pageRef, pageLimit, uId, searchQueryForApi);
};

const router = useRouter();

const pageNationErrorFunc = (status: number | undefined) => {
  if (status === 404) {
    createSnackbar({
      message: '指定されたユーザーは存在しません',
      type: 'error',
    });
    router.replace('/');
  } else {
    createSnackbar({
      message: '記事の取得に失敗しました',
      type: 'error',
    });
  }
};

const latestMarksDataAccessor = (
  data: UnwrapRef<ReturnType<typeof useUserMarkedArticles>['data']>,
) => data?.marked_articles ?? [];

const hasNextAccessor = (
  data: UnwrapRef<ReturnType<typeof useUserMarkedArticles>['data']>,
) => data?.has_next_page ?? false;

const updateArticleForArticleList = (article: AdpDocument) => {
  emitter.emit('pagination-items-update', {
    filterFunc: (item: AdpDocument) => item.id === article.id,
    updateFunc: (articles: AdpDocument[]) => {
      articles.forEach(c => {
        c.memo = article.memo;
        c.tags = article.tags;
      });
    },
  });
};
</script>

<template>
  <div v-if="targetUserInfo.id" class="o-my-activity-feed">
    <Header
      :title="targetUserInfo.user_name"
      :detail="`${targetUserInfo.email} ${mypageAccessScope}`"
      :avatar-image-url="targetUserInfo.image_url"
      :organization-tag-list="userOrganizationTags?.organization_tags"
    ></Header>
    <Content>
      <template v-if="isMypageRestricted">
        <div class="page-content">
          <div class="restrict-disclosure">
            <DgrIcon size="xlarge" name="lock" />{{ targetUserInfo.user_name }}
            さんはページの公開範囲を制限しています。
          </div>
        </div>
      </template>
      <template v-else>
        <div class="page-content">
          <div>
            <span class="c-title c-title--m"
              >マーク<span class="c-text c-text--s"
                >・{{ totalMarkedArticleCounts }} 件</span
              ></span
            >
            <div class="spacing-24"></div>
            <SearchBar
              class="search-bar"
              :placeholder="'記事を検索'"
              @on-change-query="handleChangeQuery"
              @on-search-query="fetchArticlesWithPaginateReset()"
            ></SearchBar>
            <div class="spacing-24"></div>
          </div>
          <div class="mark-page-content">
            <div class="o-feed">
              <div
                class="o-no-user-marks"
                v-if="totalMarkedArticleCounts === '0'"
              >
                <div class="o-description c-text c-text--m">
                  該当する記事はありませんでした。
                </div>
                <img src="@/assets/state-empty-article.png" />
              </div>
              <SwrScrollingPagination
                :key="`${[uId, searchQueryForApi]}`"
                :page-limit="LIMIT"
                :data-accessor="latestMarksDataAccessor"
                :has-next-accessor="hasNextAccessor"
                :pagination-func="getMarkedArticles"
                :scroll-target="'window'"
                @error="pageNationErrorFunc"
              >
                <template v-slot="{ items: articles, loaded }">
                  <div
                    class="o-article-card"
                    v-for="articleItem in articles"
                    :key="articleItem.id"
                    @click="clickArticle($event, articleItem)"
                  >
                    <AdpDocumentCard
                      :is-selected="articleSelected(articleItem.id)"
                      :enable-hover="articleHovered(articleItem.id)"
                      :page-name="PAGE_NAME"
                      :adp-document="articleItem"
                      feature="user_marks"
                      :theme-id="themeId(articleItem)"
                      :show-comment="false"
                      :show-tag-list="true"
                    ></AdpDocumentCard>
                  </div>
                  <div class="loading-icon" v-if="!loaded">
                    <DgrLoading />
                  </div>
                </template>
              </SwrScrollingPagination>
            </div>
            <div class="spacing-16"></div>
            <div class="content-right" ref="rightColumn">
              <div class="content-right-body" ref="rightContent">
                <AdpDocumentInfo
                  class="article-info"
                  v-if="selectedArticle"
                  :adp-document="selectedArticle"
                  :tag-list="articleTags"
                  :editable="false"
                  @article-info-updated="updateArticle"
                  :page-name="PAGE_NAME"
                  :has-document-export="true"
                  :is-group-view="false"
                ></AdpDocumentInfo>
                <AdpDocumentInfoDefault
                  v-else
                  :adp-document="selectedArticles"
                  :tag-list="articleTags"
                  :page-name="PAGE_NAME"
                  :has-document-export="true"
                >
                </AdpDocumentInfoDefault>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Content>
  </div>
</template>
<style lang="scss" scoped>
.o-my-activity-feed {
  width: 100%;
  margin: -24px 0 0 0;

  .page-content {
    display: flex;
    flex-direction: column;
  }

  .mark-page-content {
    display: flex;
  }

  .c-text--s {
    color: $color-gray600;
  }

  .search-bar {
    width: 616px;
  }

  .action-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 920px;
    height: 32px;
    margin-bottom: 16px;

    .left-box {
      display: flex;
      align-items: center;
    }

    .c-text--s {
      color: $color-gray600;
    }

    .tag-box {
      .tag-selectbox {
        width: auto;
      }
    }
  }

  .selected-tags {
    width: 920px;
  }

  .o-feed {
    margin-top: 0px;
    width: 616px;

    .articles-empty {
      width: 592px;
      height: 54px;
      background: #ffffff;
      border: 1px solid #e6e6e6;
      box-sizing: border-box;
      border-radius: 4px;

      .articles-empty-content {
        padding: 16px 0 16px 16px;
      }
    }
  }

  .article-info {
    background: #ffffff;
    width: 288px;
    margin-bottom: 24px;
  }

  .o-no-user-marks {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 616px;
    height: 212px;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 32px 0 48px;
    .o-description {
    }
    img {
      height: 200px;
      width: 200px;
    }
  }
  .o-article-card {
    margin-bottom: 10px;
    cursor: pointer;
  }
  .article-selected {
    border: 1px solid #1da482;
  }

  .tag-selectbox :deep(.selector-popup) {
    display: none;
  }

  .article-hovered {
    &:hover {
      border-color: #b3b3b3;
    }
  }

  .content-right {
    position: relative;
    width: 288px;
    .content-right-body {
      padding-bottom: 80px;
      width: 288px;
    }
  }

  .filters {
    display: flex;
  }
}

.export-button {
  width: 200px;
}

.restrict-disclosure {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 616px;
  height: 212px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 32px 0 48px;
}
.loading-icon {
  display: flex;
  justify-content: center;
}
</style>
