<script setup lang="ts">
import { computed, ref } from 'vue';
import { AdpDocument, UserDocument } from '@/types';
import { isUserDocument } from '@/types';
import { getPublisher } from '@/utils/adpDocument';
import { getPageUrl } from '@/utils/user-document/common';

interface Props {
  number: number;
  sourceDocument: AdpDocument | UserDocument;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  'link-clicked': [
    { adpDocument: AdpDocument | UserDocument; citationNumber: number },
  ];
}>();

const badgeElement = ref(undefined as HTMLDivElement | undefined);
const documentPublisher = computed(() => {
  if (isUserDocument(props.sourceDocument)) {
    return props.sourceDocument.last_updated_by;
  }
  return getPublisher(props.sourceDocument);
});

const documentUrl = computed(() => {
  if (!isUserDocument(props.sourceDocument)) {
    return props.sourceDocument.url;
  } else if (isPageNumberAvailable.value) {
    return props.sourceDocument.url;
  }
  const pageNumber = props.sourceDocument.page_number;
  return getPageUrl(pageNumber ?? 1, props.sourceDocument.url);
});

const handleClick = () => {
  emit('link-clicked', {
    adpDocument: props.sourceDocument,
    citationNumber: props.number,
  });
};

const isPageNumberAvailable = computed(
  () =>
    isUserDocument(props.sourceDocument) &&
    props.sourceDocument.file_type !== 'Word',
);
</script>

<template>
  <VTooltip
    placement="bottom"
    :popper-triggers="['hover', 'focus']"
    :delay="{ show: 0, hide: 200 }"
    class="number-badge c-text c-text--xs"
  >
    <div ref="badgeElement">
      <span class="hidden-char">[</span>
      <span>{{ number }}</span>
      <span class="hidden-char">]</span>
    </div>
    <template #popper>
      <div>
        <p>{{ documentPublisher }}</p>
        <a
          class="help-link"
          :href="documentUrl"
          @click="handleClick"
          target="_blank"
          rel="noopener noreferrer"
          >{{ sourceDocument.title }}</a
        >
      </div>
    </template>
  </VTooltip>
</template>

<style lang="scss" scoped>
// src/assets/global.css.scssのクラスを使用しており、背景色の値を上書き
.number-badge {
  cursor: pointer;
  background: $color-gray1000;
}

.hidden-char {
  font-size: 0px;
}
</style>
