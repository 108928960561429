<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api';
import FeaturedArticleList from '@/components/common/featured-article-list.vue';
import { useSnackbar } from '@/components/common/snackbar/use-snackbar';
import IndustryNewsPreview from '@/components/home/industry-news-preview.vue';
import MarkNews from '@/components/home/mark-news.vue';
import MobileAppInfo from '@/components/home/mobile-app-info.vue';
import PersonalNewsPreviewMultipleExecution from '@/components/home/personal-news-multiple-execution.vue';
import PickedUpFeeds from '@/components/home/picked-up-feeds.vue';
import ThemeFavoriteBoard from '@/components/home/theme-favorite-board.vue';
import { Theme } from '@/types';
import { useWebPush } from '@/utils/composables/useWebPush';
import {
  STATES,
  useTeamFeaturedArticles,
  useThemeList,
  useUserInfo,
} from '@/utils/swr';
import { useEmitter, useStore } from '@/utils/vue';
import { featureFlags } from '@/featureFlags';

export default defineComponent({
  components: {
    PickedUpFeeds,
    FeaturedArticleList,
    ThemeFavoriteBoard,
    PersonalNewsPreviewMultipleExecution,
    MarkNews,
    MobileAppInfo,
    IndustryNewsPreview,
  },
  setup(_, { emit }) {
    const store = useStore();
    const emitter = useEmitter();
    const route = useRoute();
    const { createSnackbar } = useSnackbar();

    const feedType = computed(() => store.state.feedType.feedType);
    const { data: userInfo, state: userState } = useUserInfo();
    const { data: themes, state: themesState } = useThemeList(feedType);
    const { subscribeToWebPush } = useWebPush();

    const isLoaded = computed(
      () =>
        userState.value === STATES.SUCCESS &&
        themesState.value === STATES.SUCCESS,
    );

    onMounted(async () => {
      api.trackPageView({
        pageName: 'home',
        pageUrl: route.fullPath,
      });

      // web pushの設定をユーザーが選択していない場合home画面でのみ許可を求める
      // WARN: Notification.requestPermission は本来ユーザーの操作によってのみ呼び出すべきであるため、非推奨な実装です。
      // 経緯: https://stockmarkteam.slack.com/archives/C05FHRWA342/p1709184364826779

      if ('serviceWorker' in navigator && 'Notification' in window) {
        if (Notification?.permission === 'default') {
          const permission = await Notification?.requestPermission();
          if (permission === 'granted') {
            const result = await subscribeToWebPush();
            if (!result) return;
            if (result === 'SUCCESS') {
              createSnackbar({
                message: 'Push通知が有効化されました',
                type: 'success',
              });
            } else {
              createSnackbar({
                message: 'Push通知の有効化に失敗しました',
                type: 'error',
              });
            }
          }
        }
      }
    });

    const { data: featuredArticles, mutate: updateTeamFeaturedArticles } =
      useTeamFeaturedArticles(ref(1), 10);

    const activeThemes = computed(() =>
      (themes.value?.themes ?? []).filter(t => !t.is_deleted),
    );

    const hasThemes = computed(() => activeThemes.value.length > 0);

    const showThemeSearchBoard = computed(
      () => activeThemes.value.filter(t => t.is_member).length === 0,
    );
    const showThemeFavoriteBoard = computed(
      () =>
        activeThemes.value.filter(t => t.is_member).length > 0 &&
        (activeThemes.value.filter(t => t.is_favorite) ?? []).length < 1,
    );
    const hasFavoriteThemes = computed(
      () => (activeThemes.value.filter(t => t.is_favorite) ?? []).length > 0,
    );

    emitter.on('article-updated', () => {
      updateTeamFeaturedArticles();
    });

    const toggleFavorite = async (theme: Theme) => {
      if (theme.is_favorite) {
        try {
          await api.deleteFavoriteTheme(theme.id);
          createSnackbar({
            message: 'お気に入りを解除しました',
            type: 'success',
          });
        } catch (error) {
          createSnackbar({
            message: 'お気に入りの解除に失敗しました',
            type: 'error',
          });
          throw error;
        }
      } else {
        try {
          await api.postFavoriteTheme(theme.id);
          createSnackbar({
            message: 'お気に入りに登録しました',
            type: 'success',
          });
        } catch (error) {
          createSnackbar({
            message: 'お気に入りの登録に失敗しました',
            type: 'error',
          });
          throw error;
        }
      }
      const tmpTheme = activeThemes.value.find(
        tmpTheme => tmpTheme.id === Number(theme.id),
      );
      if (tmpTheme == null) throw new Error('theme is undefined');
      tmpTheme.is_favorite = !tmpTheme.is_favorite;
      await store.commit('themeList/updateTheme', tmpTheme);
      emitter.emit('theme-updated', tmpTheme);
    };

    const showMarkNews =
      !featureFlags.ANDEV_5252_FOLLOW_LIST_MOBILE_DELETE_MARK_NEWS;

    return {
      userInfo,
      feedType,
      isLoaded,
      hasThemes,
      featuredArticles,
      showThemeSearchBoard,
      showThemeFavoriteBoard,
      hasFavoriteThemes,
      toggleFavorite,
      emit,
      showMarkNews,
    };
  },
});
</script>

<template>
  <div class="o-anews-home" v-if="isLoaded">
    <div class="o-home-body" v-if="hasThemes">
      <div class="o-home-main">
        <PersonalNewsPreviewMultipleExecution />
        <div v-if="feedType !== 'foreign'">
          <div class="spacing-16"></div>
          <IndustryNewsPreview></IndustryNewsPreview>
        </div>
        <div class="spacing-16"></div>
        <MarkNews v-if="showMarkNews" :feed-type="feedType"></MarkNews>
        <div class="o-spacing"></div>
        <div class="o-theme-favorite-board" v-if="showThemeSearchBoard">
          <ThemeFavoriteBoard></ThemeFavoriteBoard>
        </div>
        <div class="o-spacing" v-if="showThemeSearchBoard"></div>
        <div class="o-theme-favorite-board" v-if="showThemeFavoriteBoard">
          <ThemeFavoriteBoard
            @toggle-favorite="toggleFavorite"
          ></ThemeFavoriteBoard>
        </div>
        <div class="o-spacing" v-if="showThemeFavoriteBoard"></div>
        <div class="o-picked-up-feeds" v-if="hasFavoriteThemes">
          <PickedUpFeeds></PickedUpFeeds>
        </div>
      </div>
      <div class="o-team-comments" v-if="hasThemes" ref="teamCommentsColumn">
        <div
          class="o-team-comments-column-content"
          ref="teamCommentsContent"
          style="position: absolute; top: 0"
        >
          <div class="featured-articles" v-if="featuredArticles">
            <div class="o-activities">
              <FeaturedArticleList
                page-name="home"
                feature="team_feature_articles"
                :articles="featuredArticles.featured_articles.slice(0, 8)"
                link-to="/team/featured_articles"
              ></FeaturedArticleList>
              <MobileAppInfo></MobileAppInfo>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="o-has-no-theme" v-else>
      <div class="o-explanation">
        <div class="o-sentence">
          <div class="o-title c-title c-title--l">まずはテーマを作成</div>
          <div class="o-sentence1 o-text c-text c-text--m">
            毎日受け取りたいニュースのテーマを作成しましょう！
          </div>
          <div class="o-sentence2 o-text c-text c-text--m">
            どのようなテーマを作ったらいいのか悩んだときは、<br />
            お気軽に<a
              class="o-help-text"
              :href="'https://anews-stockmark.zendesk.com/hc/ja'"
              target="_blank"
              rel="noopener noreferrer"
              >ヘルプ</a
            >からカスタマーサポートにご相談ください。
          </div>
          <div class="o-footer">
            <router-link :to="{ name: 'themeCreate' }">
              <button class="c-btn c-btn--auto c-btn--AnewsPrimary">
                テーマを作成する
              </button>
            </router-link>
          </div>
        </div>
        <img class="o-no-theme" src="@/assets/state-empty-theme.png" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.o-anews-home {
  .o-home-body {
    display: flex;
    flex-direction: row;
    width: max-content;
    margin: 0 auto;
    .o-home-main {
      width: 616px;
      margin-right: 16px;
      margin-left: 16px;
      border-radius: 4px;
      .o-theme-favorite-board {
        .o-spacing {
          margin-top: 68px;
        }
      }
      .o-spacing {
        margin-top: 16px;
      }
    }
  }
  .o-has-no-theme {
    width: 940px;
    margin: auto;
    .o-explanation {
      width: 940px;
      height: 280px;
      background: #ffffff;
      border: 1px solid #e6e6e6;
      box-sizing: border-box;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .o-sentence {
        padding: 44px 88px 58px 58px;
        .o-sentence1 {
          margin-top: 12px;
        }
        .o-sentence2 {
          margin-top: 12px;
          .o-help-text {
            color: #009572;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      .o-footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 20px;
      }
      .o-no-theme {
        width: 414px;
        height: 280px;
        border-radius: 0 4px 4px 0;
      }
    }
  }
}
.o-team-comments {
  position: relative;
  width: 280px;
  margin-right: 16px;
  .featured-articles {
    padding-bottom: 80px;
  }
}
.o-has-no-comments {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 16px;
  width: 266px;
  height: 242px;
  background: #ffffff;
  .o-description {
    color: #717171;
  }
  .has-no-comments {
    height: 200px;
    width: 200px;
    margin-top: 10px;
  }
}
.o-read-more {
  font-weight: normal;
  padding: 6px 8px;
  color: #1da482;
  border-radius: 4px;
  text-align: center;
  margin: 4px 8px 0 0;
  &:hover {
    background-color: #f2f2f2;
  }
}

.o-read-more {
  margin-left: 198px;
  &:hover {
    background-color: #e6e6e6;
  }
}

button {
  outline: none;
}
.o-activities {
  width: 280px;
}
.m-mobile-app-info {
  margin-bottom: 80px;
}
</style>
